import React, {useState,useEffect} from 'react';
import { BlueSphere } from '../../assets';
import UsersTable from '../../components/Users/UsersTable';
import {useDispatch} from "react-redux";
import {get_user_list} from '../../action/userAction';
import { Link } from 'react-router-dom';
import { BigDownloadCloud, ChervonDown } from '../../assets';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Header from '../../components/utils/Header';

const Users = () => {
    const dispatch = useDispatch();

    const [user_list, setUser] = useState([]);
    const [Status, setStatus] = useState(-1); 
    const [user_name, setUserName] = useState(''); 
    const [totalUsers, setTotalUsers] = useState(0);

    useEffect(()=>{
        var payload = {
            'Status':Status,
            'UserName':user_name
        }

        var user =  dispatch(get_user_list(payload));
        user.then(function(val){
            console.log(val);
            setUser(val.data);
            setTotalUsers(val.count);
        })
    },[Status,user_name])


    return (
        <>
        <Header data={[{ title: 'Users', value: totalUsers }]} />
        <div className="my-5 mx-10">
            <div className="w-full flex justify-between">
            <div className="flex justify-start items-center gap-4 text-sm font-semibold text-kb-dark-gray mb-10">
               <div className="">
                    <span>
                        User Name
                    </span>
                    <div className={`flex flex-col text-sm col-span-2 lg:col-span-1`}>
                    <input
                        className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
                        placeholder='Filter By User Name'
                        type="text"
                        onChange={(e) => setUserName(e.target.value)}
                    />
                </div>
                </div>
                
                <div className="grid">
                    <span>
                        Status
                    </span>
                    <select
                className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
                onChange={(e) => setStatus(e.target.value)}
            >
                <option className="py-2" value={-1} > All </option>
                    <option className="py-2" value={1} >True</option>
                    <option className="py-2" value={0} >False</option>
            </select>
                </div>
            </div>
            <div className="flex justify-start items-center gap-4 text-sm font-semibold text-kb-dark-gray mb-10">
                    <button onClick={() => ExpoetExcel(user_list,'UserData')}>
                        <img src={BigDownloadCloud} className="h-11" alt="download" />
                    </button>
            </div>
        </div>
            <UsersTable
                data={user_list}
            />
        </div>
        </>

    );
};



const ExpoetExcel =(excelData,fileName) => {
    var Export_data = [];
    for (let i = 0; i < excelData.length; i++) {
        Export_data.push({
            'User Name': excelData[i].name,
            'Phone' : excelData[i].country_code + ' ' +excelData[i].phone ,
            'Email' :  excelData[i].email,
            'Gender' : excelData[i].gender,
            'Status': excelData[i].is_active,
            'Card': excelData[i].total_card.length,
            'Application': excelData[i].total_application.length,
        });
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(Export_data);
        const wb = {Sheets:{'data':ws},SheetNames:['data']};
        const excelBuffer = XLSX.write(wb,{bookType:'xlsx',type:'array'});
        const data = new Blob([excelBuffer],{type:fileType});
        FileSaver.saveAs(data,fileName+fileExtension)
}

export default Users;
