import React, { useState } from 'react';
import Table from '../utils/Table';
import OutletTableActions from './OutletTableActions';

const OutletsTable = ({ data }) => {
    const [activeRow, setActiveRow] = useState(0);

    const updateActiveRow = (id) => {
        setActiveRow(activeRow !== id ? id : -1);
    };

    const COLUMNS = [
        {
            Header: '☑️',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <input type="checkbox" />
                </div>
            )
        },
        {
            Header: 'Brand/Outlet',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        src={tableProps.row.original.logo}
                        className="w-16 rounded-lg mr-4"
                        alt={tableProps.row.original.outletname}
                    />
                    <span>{tableProps.row.original.outletname}</span>
                </div>
            )
        },

        {
            Header: 'Country',
            accessor: 'country'
        },
        {
            Header: 'Location',
            accessor: 'location'
        },
        {
            Header: 'Latitude',
            accessor: 'outlet_latitude'
        },
        {
            Header: 'Longitude',
            accessor: 'outlet_longitude'
        },
        {
            Header: 'Offers',
            Cell: (tableProps) => <span>'offers'</span>
        },
        {
            Header: 'Status',
            Cell: (tableProps) => (tableProps.row.original.is_active.toString())
        },
        {
            Header: 'Actions',
            Cell: (tableProps) => (
                  tableProps.row.original.is_active ? 
                  <OutletTableActions data={tableProps.row.original._id} /> 
                  : ''
            )
        }
    ];

    return <Table rowData={data} columnData={COLUMNS} />;
};

export default OutletsTable;
