import React from 'react';
import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router-dom';

const Layout = ({ children }) => {
    return (
        <main className="flex">
            <Sidebar />
            <section className="flex-1">
                <Outlet />
            </section>
        </main>
    );
};

export default Layout;
