import { BankIcon, CategoryIcon, OutletIcon, OverviewIcon, UserIcon,LogoutIcon } from '../assets';
import PercentageIcon from '../assets/icons/PercentageIcon';

const data = [
    {
        title: 'Overview',
        icon: {
            active: <OverviewIcon color="#ffffff" />,
            normal: <OverviewIcon color="#808191" />
        },
        to: '/overview'
    },
    {
        title: 'Bank & Cards',
        icon: {
            active: <BankIcon color="#ffffff" />,
            normal: <BankIcon color="#808191" />
        },
        to: '/banks'
    },
    {
        title: 'Application',
        icon: {
            active: <BankIcon color="#ffffff" />,
            normal: <BankIcon color="#808191" />
        },
        to: '/applications'
    },
    {
        title: 'Users',
        icon: {
            active: <UserIcon color="#ffffff" />,
            normal: <UserIcon color="#808191" />
        },
        to: '/users'
    },
    {
        title: 'Outlet & Shops',
        icon: {
            active: <OutletIcon color="#ffffff" />,
            normal: <OutletIcon color="#808191" />
        },
        to: '/outlets'
    },
    {
        title: 'Offers',
        icon: {
            active: <PercentageIcon color="#ffffff" />,
            normal: <PercentageIcon color="#808191" />
        },
        to: '/offers'
    },
    {
        title: 'Category',
        icon: {
            active: <CategoryIcon color="#ffffff" />,
            normal: <CategoryIcon color="#808191" />
        },
        to: '/category'
    },
    {
        title: 'Static',
        icon: {
            active: <UserIcon color="#ffffff" />,
            normal: <UserIcon color="#808191" />
        },
        to: '/static'
    },
    {
        title: 'Outlet By User',
        icon: {
            active: <OutletIcon color="#ffffff" />,
            normal: <OutletIcon color="#808191" />
        },
        to: '/outlet_by_user'
    },
    {
        title: 'Logout',
        icon: {
            active: <LogoutIcon color="#ffffff" />,
            normal: <LogoutIcon color="#808191" />
        },
        to: '/logout'
    }
];

export default data;
