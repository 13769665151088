import React, { useState } from 'react';
import Table from '../utils/Table';
import ActionCard from '../utils/ActionCard';
import { EditIcon, TrashIcon } from '../../assets';

const OutletOffersTable = ({ data }) => {
    const [activeRow, setActiveRow] = useState(0);

    const updateActiveRow = (id) => {
        setActiveRow(activeRow !== id ? id : -1);
    };

    const ACTIONS = [
        {
            icon: EditIcon,
            title: 'Edit ',
            action: () => console.log('edit Card Clicked of', activeRow)
        },
        {
            icon: TrashIcon,
            title: 'Delete ',
            action: () => console.log('trash Clicked of id', activeRow)
        }
    ];

    const COLUMNS = [
        {
            Header: 'Offertag',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <input type="checkbox" />
                </div>
            )
        },
        {
            Header: 'offerdesc',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        src={tableProps.row.original.brand_logo}
                        className="w-16 rounded-lg mr-4"
                        alt={tableProps.row.original.brand_name}
                    />
                </div>
            )
        },

        {
            Header: 'expdt',
            accessor: 'offer_desc'
        },
        {
            Header: 'Offerbank',
            accessor: 'offertag'
        },
        {
            Header: 'Offeronbankcard',
            accessor: 'expdt'
        },
        {
            Header: 'offercardtype',
            accessor: 'offercardtype'
        },
        {
            Header: 'Country',
            accessor: 'country'
        },
        {
            Header: 'location',
            accessor: 'location'
        },
        {
            Header: 'Outlet Lat',
            Cell: 'outlet_lat'
        },
        {
            Header: 'Actions',
            Cell: (tableProps) => (
                <ActionCard
                    active={tableProps.row.original.id === activeRow}
                    actionData={ACTIONS}
                    onClick={() => updateActiveRow(tableProps.row.original.id)}
                />
            )
        }
    ];

    return (
        <div>
            <Table columnData={COLUMNS} rowData={data} />
        </div>
    );
};

export default OutletOffersTable;
