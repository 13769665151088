import React from 'react';
import Button from '../utils/Button';

const CardDescription = (data) => {
    console.log(data.data.length);
    return (
        <div className="bg-white text-sm rounded-lg p-7">
            <span dangerouslySetInnerHTML={{__html: data.data.length === 0 ? ' ' :  data.data.carddtl[0].other_description === undefined ? '' : data.data.carddtl[0].other_description}}></span>
        </div>
    );
};

export default CardDescription;
