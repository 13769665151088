import React, { useState } from 'react';
import { BankIcon, EditIcon, TrashIcon, VerticalDotsIcon } from '../../assets';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { application_delete } from '../../action/applicationAction';

const ApplicationActionCard = (data) => {
    const [actions, setActions] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ACTIONS = [
        {
            icon: BankIcon,
            title: 'Send To Bank',
            action: () => console.log("IN")
        },
        {
            icon: TrashIcon,
            title: 'Delete',
            action: () => ApplicationDelete(data.data)
        }
    ];

    const ApplicationDelete = async (id) => {
        console.log("in", id);
        var dtl = {
            'Id': id
        }
        var cat = await dispatch(application_delete(dtl));
        if (cat.success) {
            window.location.reload();
        }
    }


    return (
        <div className="pl-3 relative">
            <button className="py-8"
                onMouseEnter={() => setActions(true)}
                onMouseLeave={() => setActions(false)}>
                <VerticalDotsIcon />
                {actions && (
                    <div className="items-start top-16 -left-16 flex py-2 w-40 flex-col absolute bg-white z-40 shadow-xl border rounded-lg border-kb-light-gray text-kb-dark-gray">
                        {ACTIONS.map((item) => (

                            <button
                                key={item.title}
                                className="px-4 py-2 w-full rounded-md flex gap-3 items-center hover:bg-kb-light-blue hover:text-kb-light-blue hover:bg-opacity-20"
                                onClick={item.action}
                            >
                                <item.icon />
                                <span>{item.title}</span>
                            </button>

                        ))}
                    </div>
                )}
            </button>
        </div>
    );
};

export default ApplicationActionCard;
