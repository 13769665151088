import React from 'react';
import { Link, Outlet, useMatch, useResolvedPath } from 'react-router-dom';

const NavLink = ({ to, title }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    console.log(match);
    return (
        <Link to={`/offers/${to}`}>
            <span
                className={`py-[17.5px] px-5  ${
                    match
                        ? 'border-b-4 border-blue-500 text-kb-light-blue font-bold'
                        : 'text-[#3F8CFF] font-semibold opacity-50'
                }`}
            >
                {title}
            </span>
        </Link>
    );
};

const OffersLayout = ({ children }) => {
    return (
        <>
            <div className="py-5 px-10 shadow-md">
                <nav className="flex justify-between ">
                    {[
                        { title: 'Bank Offers', to: 'bank-offers' },
                        { title: 'Cards Offers', to: 'cards-offers' },
                        { title: 'Exciting Offers Banks', to: 'exciting-offers-bank' },
                        { title: 'Exciting Offers Brand', to: 'exciting-offers-brand' },
                        { title: 'Outlet Offers', to: 'outlet-offers' }
                    ].map((item) => (
                        <NavLink key={item.to} to={item.to} title={item.title} />
                    ))}
                </nav>
            </div>
            <Outlet />
        </>
    );
};

export default OffersLayout;
