import React from 'react';

function OutletIcon({ color = '#808191' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.5"
            height="19.114"
            viewBox="0 0 17.5 19.114"
        >
            <g id="_Icon" data-name="🍎Icon" transform="translate(0.75 0.75)" opacity="1">
                <g id="shopping-bag" transform="translate(-3 -2)">
                    <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M5.642,2,3,5.523v12.33a1.761,1.761,0,0,0,1.761,1.761h12.33a1.761,1.761,0,0,0,1.761-1.761V5.523L16.211,2Z"
                        fill="none"
                        stroke={color}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <line
                        id="Line_39"
                        data-name="Line 39"
                        x2="16"
                        transform="translate(3 5.614)"
                        fill="none"
                        stroke={color}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M16,10a4,4,0,0,1-8,0"
                        transform="translate(-1.073 -1.193)"
                        fill="none"
                        stroke={color}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                </g>
            </g>
        </svg>
    );
}

export default OutletIcon;
