import {
    LOGIN_SUCCESS, LOGIN_REQUEST, LOGIN_FAIL,
    CLEAR_ERRORS, LOGOUT_SUCCESS,
    LOGOUT_FAIL,
} from "../constants/loginConstants"

export const loginReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload,
            };

        case LOGOUT_SUCCESS:
            return {
                loading: false,
                user: null,
                isAuthenticated: false,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: action.payload,
                error: action.payload,
            };
        case LOGOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };


        default:
            return state;
    }
};