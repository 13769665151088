import React, { useState } from 'react';
import { EditIcon, TrashIcon,VerticalDotsIcon,ApplicationIcon } from '../../assets';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";
import { Outlet_Delete } from '../../action/outletAction';

const OutletTableActions = (data) => {
    const [activeRow, setActiveRow] = useState(data.data);
    const [actions, setActions] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ACTIONS = [
        {
            icon: EditIcon,
            title: 'Edit',
            action: () =>   navigate('/outlets/edit/'+data.data)
        },
        {
            icon: ApplicationIcon,
            title: 'Outlet Details',
            action: () => navigate('/outlets/'+data.data)
        },
        {
            icon: TrashIcon,
            title: 'Delete',
            action: () => OutletDelete(data.data)
        }
    ];

    const OutletDelete = async(id) =>{
        console.log("in",id);
        var dtl ={
            'Id':id
        }
        var cat = await dispatch(Outlet_Delete(dtl));
        if (cat.success) {
           window.location.reload();
        }
    }


    return  <div className="pl-3 relative">
    <button  className="py-3"
        onMouseEnter={() => setActions(true)}
        onMouseLeave={() => setActions(false)}>
        <VerticalDotsIcon />
        {actions && (
            <div className="items-start top-8 -left-16 flex py-2 w-40 flex-col absolute bg-white z-40 shadow-xl border rounded-lg border-kb-light-gray text-kb-dark-gray">
                {ACTIONS.map((item) => (
                    <button
                        key={item.title}
                        className="px-4 py-2 w-full rounded-md flex gap-3 items-center hover:bg-kb-light-blue hover:text-kb-light-blue hover:bg-opacity-20"
                        onClick={item.action}
                    >
                        <item.icon />
                        <span >{item.title}</span>
                    </button>
                ))}
            </div>
        )}
    </button>
</div>;
};

export default OutletTableActions;
