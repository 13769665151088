import React from 'react';

function TrashIcon({ color = '#6e6b7b' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.473"
            height="14.859"
            viewBox="0 0 13.473 14.859"
        >
            <g id="trash_1_" data-name="trash (1)" transform="translate(0.5 0.5)">
                <path
                    id="Path_49587"
                    data-name="Path 49587"
                    d="M3,6H15.473"
                    transform="translate(-3 -3.228)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
                <path
                    id="Path_49588"
                    data-name="Path 49588"
                    d="M14.7,4.772v9.7a1.386,1.386,0,0,1-1.386,1.386H6.386A1.386,1.386,0,0,1,5,14.473v-9.7m2.079,0V3.386A1.386,1.386,0,0,1,8.465,2h2.772a1.386,1.386,0,0,1,1.386,1.386V4.772"
                    transform="translate(-3.614 -2)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
            </g>
        </svg>
    );
}

export default TrashIcon;
