import React, { useEffect, useState } from 'react';
import Button from '../components/utils/Button';
import { InputSelect, InputText } from '../components/utils/Input';
import { KartBlockLogo } from '../assets';
import { Navigate, useNavigate } from 'react-router-dom';
import {login,clearErrors} from '../action/loginAction';
import {useDispatch,useSelector} from "react-redux";
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {error,isAuthenticated} = useSelector(
        (state) => state.user 
      );
     

    const [formValues, setFormValues] = useState({
        userType: '',
        email: '',
        password: ''
    });
    const [loggedIn, setLoggedIn] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevState) => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

   const handleSubmit = async(event) => {
        event.preventDefault();
        console.log("call",window.location.href);
        const data = await dispatch(login(formValues.email,formValues.password));
        console.log(data);
        if (data !== undefined && data.isAuthenticated ) {
            navigate('overview')
            window.location.reload();
        }
        if (data.success === false) {
            toast.warn(data.message);
            //dispatch(clearErrors());
          }
    };

    useEffect(() => {
        // if (isAuthenticated) {
        //     return navigate('/overview');
        // }
        // if (getalldt.success === false && getalldt.success !==  undefined) {
        //     toast.warn(getalldt.message);
        //     //dispatch(clearErrors());
        //   }
        //   if (error) {
        //     toast.warn(error);
        //     //dispatch(clearErrors());
        //   }
      
        //   if (isAuthenticated) {
        //     history.push(redirect);
        //   }
        if (cookies.get('token') !== undefined) {
            return navigate("/overview")
        }
        else{
            return navigate("/")
        }
    }, []);

    return (
        <div className="shadow-inner-xl flex justify-center items-center w-full h-screen bg-kb-light-gray bg-opacity-30">
            <div className="bg-white py-4 lg:py-8 px-6 lg:px-12 mx-8 w-full md:w-1/2 lg:w-1/3  relative rounded-2xl flex flex-col justify-center">
                <div className="h-full top-16 bg-[#E3E6EC] mix-blend-multiply rounded-3xl blur-2xl w-full opacity-90 -z-10 absolute" />
                <ToastContainer />
                <img src={KartBlockLogo} alt="logo" className="h-16 p-4 object-contain " />
                <h2 className="text-3xl mb-4 font-bold">LOGIN</h2>
                <form className="gap-4 flex flex-col" onSubmit={handleSubmit}>
                    {/* <InputSelect
                        name="userType"
                        onChange={handleChange}
                        label="User Type"
                        value={formValues.userType}
                        options={[
                            { name: 'Admin', value: 0 }
                          
                        ]}
                    /> */}
                    <InputText
                        name="email"
                        onChange={handleChange}
                        label="Email"
                        value={formValues.email}
                        type="email"
                    />
                    <InputText
                        name="password"
                        onChange={handleChange}
                        label="Password"
                        value={formValues.password}
                        type="password">
                    </InputText>
                    <Button type="blueSolid" classes="mt-4 text-lg" >
                        Submit
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default Login;
