import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BankLogo, LeftArrow } from '../assets';

const BankLayout = ({ children, actions,data }) => {
    const navigate = useNavigate();
    console.log(data);
    data = data === undefined || data === null? '' : data
    return (
        <main>
            <div className="bg-white flex justify-between items-center py-5 pl-10 pr-16 sticky top-0 shadow-md z-10">
                <div className="flex items-center">
                    <span className="mr-5" onClick={() => navigate(-1)}>
                        <img src={LeftArrow} alt="leftarrow" className="w-12 " />
                    </span>
                    <span className="mr-4">
                        <img src={data !== undefined ? data.length !== 0  ? data.bank_logo : '' : ''} alt="bank_logo" className="w-16" />
                    </span>
                    <span className="font-bold text-base text-[##11142D]">{ data !== undefined ? data.length !== 0 ? data.bank_name :'':''}</span>
                </div>
                {actions}
            </div>
            {children}
        </main>
    );
};

export default BankLayout;
