
import axios from "axios";
import { HOSTNAME } from './APIConstant';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const get_user_list = (payload) => async (dispatch) => {
  try {

    const config = { headers: { "Content-Type": "application/json", 'token': cookies.get('token') } };

    const { data } = await axios.post(
      HOSTNAME + `/api/v1/admin/users`,
      payload,
      config
    );
    console.log(data);
    return (data);
  } catch (error) {
    dispatch({ payload: error.response.data.message });
  }
};


export const user_dtl_byid = (userid) => async (dispatch) => {
  try {

    const config = { headers: { "Content-Type": "application/json", 'token': cookies.get('token') } };

    const { data } = await axios.post(
      HOSTNAME + `/api/v1/admin/userdtl`,
      userid,
      config
    );
    console.log(data);
    return (data);
  } catch (error) {
    console.log(error);
    dispatch({ payload: error.response.data.message });
  }
};

export const get_user_count = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json", 'token': cookies.get('token') } };

    const { data } = await axios.get(
      HOSTNAME + `/api/v1/usercount`,
      config
    );
    console.log(data);
    return (data.data);
  } catch (error) {
    return (error.response.data.message);
  }
};

export const user_del = (payload) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json", 'token': cookies.get('token') } };


    const { data } = await axios.post(
      HOSTNAME + `/api/v1/deleteuser`,
      payload,
      config
    );
    console.log(data);
    return (data);
  } catch (error) {
    return error.message;
  }
};

export const user_block = (payload) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json", 'token': cookies.get('token') } };


    const { data } = await axios.post(
      HOSTNAME + `/api/v1/admin/block`,
      payload,
      config
    );
    console.log(data);
    return (data);
  } catch (error) {
    return error.message;
  }
};