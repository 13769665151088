import axios from "axios";
import {HOSTNAME} from './APIConstant';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const outlet_list = () => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.get(
        HOSTNAME + `/api/v1/useroutlet/getall`,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      dispatch({ payload: error.response.data.message });
    }
  };

  export const UserOutlet_Delete = (outletdtl) => async (dispatch) => {
    try {
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
     
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/useroutlet/delete`,
          outletdtl,
          config
        );
          console.log(data);
        return (data);
    } catch (error) {
      return error.message;
    }
  };

  export const ConvertUserOutlet = (outletdtl) => async (dispatch) => {
    try {
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
     
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/useroutlet/covertoutlet`,
          outletdtl,
          config
        );
          console.log(data);
        return (data);
    } catch (error) {
      return error.message;
    }
  };