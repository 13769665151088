import React, {useState} from 'react';
import { BlueSphere } from '../../assets';
import CategoryActions from '../../components/Category/CategoryActions';
import CategoryTable from '../../components/Category/CategoryTable';
import {useDispatch} from "react-redux";
import {cat_chart, cat_list} from '../../action/categoryAction';
import Chart from "react-apexcharts";

const Category = () => {
    const [cat_chart, setCategory] = useState([]);
    const [cat_list, setCategoryList] = useState([]);
    
    if (cat_chart.length === 0) {
    CategoryTabledata().then(data => {
        console.log(data);
        setCategory(data.cat_ch.data);
        setCategoryList(data.cat_ls.data);
    });
    console.log("catdtlo",cat_chart);
    }

  
    
    return (
        <>
            <div className="w-full pt-11 pb-8 px-10 flex text-3xl gap-10">
                <div className="flex items-center">
                    <img src={BlueSphere} alt="none" className="w-14 h-14 mr-4" />
                    <span className=" font-bold">{cat_chart.length}&nbsp; </span>
                    <span className="font-light">Total Categories</span>
                </div>
            </div>
            <hr />
            <div className="px-10 py-5">
                <CategoryActions />
                {/* { cat_list.map((item) => (
                    <CategoryTable data={[{ title: item.name, logo: item.image, is_active: item.is_active }]} />
                )) 
                 } */}
                 <CategoryTable data={cat_list} />
                
                <br></br>
                &nbsp;
                 
            <Chart
              type="bar"
              width={1500}
              height={500}
                
              series={[
                {
                    name:"Total Spent",
                    data:cat_chart.map((item) => item.total)
                }
            ]}
            options={{
                title:{text:"Categories vise spendings",
                style:{fontSize:20}
            },
            colors:['#557095'],
            xaxis:{
                categories:cat_chart.map((item) => item.cat_name)
            },
            dataLabels:{
                formatter:(val)=>{return ''}
            },
            
            }}
            />
          

            </div>
        </>
    );
};


const CategoryTabledata = async() =>{
   
    const dispatch = useDispatch();

    var cat_ls = await dispatch(cat_list());
    var cat_ch = await dispatch(cat_chart());
    return {"cat_ch": cat_ch,"cat_ls":cat_ls};
}

export default Category;
