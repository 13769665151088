import React from 'react';
import { Link } from 'react-router-dom';

const CategoryActions = () => {
    return (
        <div className="w-full flex justify-between">
            <div className="flex justify-start items-center gap-4 text-sm font-semibold text-kb-dark-gray ">
                <div className="bg-kb-light-gray px-6 py-2 ">Filter 1 </div>
                <div className="bg-kb-light-gray px-6 py-2 ">Filter 1</div>
                <div className="bg-kb-light-gray px-6 py-2 ">Search</div>
            </div>
            <div className="flex">
                <Link to="/category/add">
                    <button className="rounded-xl bg-kb-light-blue py-3 px-7 text-white font-bold relative">
                        <span className="w-full h-full bg-[#E3E6EC] absolute top-0 left-0 blur-md mix-blend-multiply -z-10 border"></span>
                        + Add Category
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default CategoryActions;
