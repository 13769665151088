import React from 'react';

function LogoutIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="19.5" class="ionicon" viewBox="0 0 512 512">
            <g id="_Icon" data-name="🍎Icon" transform="translate(0.75 0.75)">
                 <g id="user" transform="translate(-4 -3)" opacity="1">
            <path d="M378 108a191.41 191.41 0 0170 148c0 106-86 192-192 192S64 362 64 256a192 192 0 0169-148M256 64v192" 
            fill="none" 
            stroke={color} 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="32"/>
            <circle
                         id="Ellipse_46"
                         data-name="Ellipse 46"
                         cx="4"
                         cy="4"
                         r="4"
                         transform="translate(8 3)"
                         fill="none"
                         stroke={color}
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="1.5"
                     />
            </g>
             </g>
            </svg>
    );
}

export default LogoutIcon;