import React from 'react';
import About from '../components/Static/About';
import Privacy from '../components/Static/Privacy';
import TermsAndCondition from '../components/Static/TermsAndCondition';

const Static = () => {
    return (
        <div>
            <About />
            <Privacy />
            <TermsAndCondition />
        </div>
    );
};

export default Static;
