import React from 'react';
import { DownloadCloud } from '../../assets';
import Button from '../utils/Button';
import { Link } from 'react-router-dom';

const CardHeader = (data) => {
    console.log(data);
    return (
        <div className="bg-white px-4 py-7 flex rounded-lg ">
            <div className="mr-10">
                <img
                    src={data.data.length === 0 ? '' : data.data.carddtl[0].card_image}
                    alt="card_logo"
                    className="w-52 h-32 rounded-lg"
                />
            </div>
            <div className="flex-1">
                <h2 className="font-semibold text-[22px] text-[#11142D]">
                    {data.data.length === 0 ? '' :  data.data.carddtl[0].card_name}
                </h2>
                <h4 className="font-normal text-lg text=[#11142D] opacity-60 mb-7">
                    Travel credit cards
                </h4>
                <button className="text-kb-light-blue flex items-center">
                    <span className="mr-2">{data.data.length === 0 ? '' :  data.data.application} Applications</span>
                    <img src={DownloadCloud} alt="download" />
                </button>
            </div>
            <div className="flex justify-end items-start gap-3">
            <Link to={data.data.length === 0 ? '' : '/banks/'+data.data.carddtl[0].bank_id._id+'/edit-upload-card/'+data.data.carddtl[0]._id} state={data.data.length === 0 ? '' :data.data.carddtl[0].bank_id}>
                <Button type="blueSolid">Edit</Button>
            </Link>
                <Button type="redBorder">Delete</Button>
            </div>
        </div>
    );
};

export default CardHeader;
