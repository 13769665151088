  import axios from "axios";
  import {HOSTNAME} from './APIConstant';
  import Cookies from 'universal-cookie';
  const cookies = new Cookies();

export const bankcard_list = (bankid) => async (dispatch) => {
    try {
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.post(
        HOSTNAME+ `/api/v1/bankcard/bankcardList`,
        bankid,
        config
      );
      return (data);
    } catch (error) {
      return error.response.data.message;
    }
  };

  export const bankcard_add = (carddtl) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "multipart/form-data",'token':cookies.get('token') } };
  
      if (carddtl.Id !== '') {
        const { data } = await axios.post(
          HOSTNAME+ `/api/v1/bankcard/update`,
          carddtl,
          config
        );
        return (data);
      }
      else{
        const { data } = await axios.post(
          HOSTNAME+ `/api/v1/bankcard/addCard`,
          carddtl,
          config
        );
        return (data);
      }
    } catch (error) {
      return error.message;
    }
  };

  export const getcarddtlbyid = (id) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.post(
        HOSTNAME + `/api/v1/bankcard/byid`,
        id,
        config
      );
      return (data);
    } catch (error) {
      return error.message;
    }
  };

  export const bankcard_del = (carddtl) => async (dispatch) => {
    try {
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
     
        const { data } = await axios.post(
          HOSTNAME+ `/api/v1/bankcard/delete`,
          carddtl,
          config
        );
        return (data);
    } catch (error) {
      return error.message;
    }
  };



  export const getautocardtype_list = () => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.get(
        HOSTNAME+ `/api/v1/bankcard/getcardtype`,
        config
      );
      return (data);
    } catch (error) {
      return (error.response.data.message);
    }
  };


  export const getcarddtlall = (cardid) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.post(
        HOSTNAME+ `/api/v1/bankcard/getcarddtlall`,
        cardid,
        config
      );
      return (data);
    } catch (error) {
      return (error.response.data.message);
    }
  };
