import React, { useState } from 'react';
import OfferForm from '../../components/Banks/OfferForm';
import Button from '../../components/utils/Button';
import BankLayout from '../../layouts/BankLayout';

const UploadOffer = () => {
    const [values, setValues] = useState({
        cardName: '',
        cardImage: '',
        cardType: '',
        annualRate: '',
        balanceTransfer: '',
        cashBack: '',
        minSalary: '',
        fxRate: '',
        monthlyFees: '',
        cardDesc: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues((prevState) => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };
    return (
        <BankLayout
            actions={
                <div className="flex gap-2">
                    <Button type="redBorder">Cancel</Button>
                    <Button type="blueSolid">Upload</Button>
                </div>
            }
        >
            <div className="px-11 py-5 bg-kb-light-gray bg-opacity-30">
                <OfferForm values={values} onChange={handleChange} />
            </div>
        </BankLayout>
    );
};

export default UploadOffer;
