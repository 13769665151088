import React from 'react';

function EditIcon({ color = '#6e6b7b' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.531"
            height="14.531"
            viewBox="0 0 14.531 14.531"
        >
            <g id="edit" transform="translate(0.5 0.5)">
                <path
                    id="Path_49585"
                    data-name="Path 49585"
                    d="M7.968,4H3.326A1.326,1.326,0,0,0,2,5.326V14.61a1.326,1.326,0,0,0,1.326,1.326H12.61a1.326,1.326,0,0,0,1.326-1.326V9.968"
                    transform="translate(-2 -2.405)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
                <path
                    id="Path_49586"
                    data-name="Path 49586"
                    d="M15.893,2.346A1.595,1.595,0,1,1,18.149,4.6l-7.142,7.142L8,12.494l.752-3.007Z"
                    transform="translate(-5.084 -1.879)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
            </g>
        </svg>
    );
}

export default EditIcon;
