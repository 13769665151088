import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LeftArrow } from '../../assets';
import BankForm from '../../components/Banks/BankForm';
import Button from '../../components/utils/Button';

const AddBank = () => {
    const navigate = useNavigate();


    return (
        <div>
            {/* <div className="bg-white py-5 pl-10 pr-16 flex justify-between items-center shadow-lg">
                <div className="flex items-center">
                    <span className="mr-5" onClick={() => navigate(-1)}>
                        <img src={LeftArrow} alt="leftarrow" className="w-12 " />
                    </span>
                    <span className="font-bold text-xl">Add new Bank</span>
                </div>
                <div className="flex gap-3 text-sm">
                    <Button type="redBorder">
                        <span>Cancel</span>
                    </Button>
                    <Button type="Submit" className="blueSolid" onClick={handleSubmit}>Create</Button>
                </div>
            </div>
            <div className="w-full h-full bg-kb-light-gray bg-opacity-30 py-4 px-11"> */}
                <BankForm />
            {/* </div> */}
        </div>
    );
};

export default AddBank;
