import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Table from '../utils/Table';
import { ApplicationIcon, TrashIcon } from '../../assets';
import TableGlobalSearch from '../utils/TableGlobalSearch';
import OfferTableActions from './OfferTableActions';

const BrandExcitingOffersTable = ({ data }) => {
    const [activeRow, setActiveRow] = useState(0);

    let navigate = useNavigate();
    const updateActiveRow = (id) => {
        setActiveRow(activeRow !== id ? id : -1);
    };

  

    const COLUMNS = [
       

        {
            Header: 'Offertag',
            accessor: 'offer_tag'
        },
        {
            Header: 'Offerdesc',
            Cell: (tableProps) => <span dangerouslySetInnerHTML={{__html: tableProps.row.original.other_text.length > 100 ? tableProps.row.original.other_text.substr(0, 60) + ' ....' : tableProps.row.original.other_text}}></span>
        },
        {
            Header: 'Expdt',
            Cell: (tableProps) => <span className="">{tableProps.row.original.offer_expiry_date.split('T')[0]}</span>
        },
        {
            Header: 'Outlet/Brand',
            accessor: 'outlet_id',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        src={tableProps.row.original.outlet_id.logo}
                        className="w-11 rounded-full mr-4"
                        alt={tableProps.row.original.first_name}
                    />
                </div>
            )
        },
        {
            Header: 'OfferOnCard',
            Cell: (tableProps) => <span className="">{tableProps.row.original.card_id.card_name}</span>
        },
        {
            Header: 'OfferCardType',
            Cell: (tableProps) => <span className="">{tableProps.row.original.card_type.name}</span> 
        },
        {
            Header: 'Country',
            Cell: (tableProps) => <span className="">{tableProps.row.original.country !== null ? tableProps.row.original.country.name :""}</span> 
        },
        {
            Header: 'Location',
            Cell: (tableProps) => <span className="">{tableProps.row.original.location !== null ? tableProps.row.original.location.name : ""}</span> 
        },
        {
            Header: 'ImageURL',
            Cell: (tableProps) => <span className="">URL</span>
        },
        // {
        //     Header: 'Applications',
        //     Cell: (tableProps) => (
        //         <span className="text-kb-light-blue">
        //             {tableProps.row.original.applications} Applications
        //         </span>
        //     )
        // },

        {
            Header: 'Actions',
            Cell: (tableProps) => (
                <OfferTableActions
                data={tableProps.row.original._id}
                />
            )
        }
    ];

    return (
        <>
            <div className="flex mb-8">
                <TableGlobalSearch />
            </div>
            <Table columnData={COLUMNS} rowData={data} />;
        </>
    );
};

export default BrandExcitingOffersTable;
