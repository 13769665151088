import React from 'react';
import { SearchIcon } from '../../assets';

const TableGlobalSearch = ({ filter, setFilter }) => {
    return (
        <div className="bg-[#E4E4E440] px-4 py-3 rounded-2xl flex items-center">
            <SearchIcon />
            <input
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Search"
                className="ml-4 bg-transparent border-none outline-none"
            />
        </div>
    );
};

export default TableGlobalSearch;
