import React from 'react';
import Button from '../utils/Button';

const CardDetails = (data) => {
    console.log(data);
    return (
        <div className="bg-white rounded-lg p-7">
            <div className="flex items-center justify-between mb-6">
                <h2 className="text-[#11142D] text-base font-semibold">
                    Card Details For Comparison
                </h2>
                <Button className="text-kb-light-blue text-sm font-semibold">Edit</Button>
            </div>
            <hr className="mb-3" />
            <div className="flex h-32 flex-wrap">
                {[
                    { title: 'Annual Percentage Rate', value:data.data.length === 0 ? ''  : data.data.carddtl[0].annual_charges },
                    { title: 'Fx Rate', value: data.data.length === 0 ? ''  :  data.data.carddtl[0].fx_rate },
                    { title: 'Balance Transfer', value:data.data.length === 0 ? ''  : data.data.carddtl[0].balance_transfer },
                    { title: 'Monthly Fee', value:data.data.length === 0 ? ''  : data.data.carddtl[0].monthly_fees },
                    { title: 'Cashback', value:data.data.length === 0 ? ''  : data.data.carddtl[0].cashback },
                    { title: 'Min Salary', value:data.data.length === 0 ? ''  : data.data.carddtl[0].minimum_salary }
                ].map((item) => (
                    <div className="w-1/2 flex pb-4 text-sm">
                        <span className="w-48 mr-2 text-[#B2B3BD] ">{item.title}</span>
                        <span className="mr-12">:</span>
                        <span className="text-[#11142D]">{item.value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CardDetails;
