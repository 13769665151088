import React, { useState,useEffect } from 'react';
import { InputFile, InputText,InputSelect } from '../utils/Input';
import {bank_add,city_list,country_list,getbankdtlbyid, state_list} from '../../action/bankAction';
import {useDispatch,useSelector} from "react-redux";
import Button from '../../components/utils/Button';
import { LeftArrow } from '../../assets';
import { useNavigate } from 'react-router-dom';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Autocomplete from 'react-autocomplete'
import BodyTextEditor from '../utils/TextEditor';

const BankForm = () => {
    const Id = window.location.href.split('/')[5];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const data= useSelector(
        (state) => state.bank 
      );

    
   const [formData, setFormData] = useState({
    bank_logo:'',
    bank_name:'',
    bank_ifsc_code:'',
    bank_full_address:'',
    bank_country:'',
    state:'',
    city:'',
    other_description:'',
    citylist:[],
    statelist:[],
    countrylist:[],
    id:Id
});
   

    const dtlbyid = async () => {
        const data={
            Id:Id
        }
        var dtl = await dispatch(getbankdtlbyid(data));
        console.log(dtl);
    if (dtl.success) {
        statedtl(dtl.data[0].bank_country)
        citydtl(dtl.data[0].state[0])
        setFormData((prevState) => {
            prevState.id = Id;
            prevState.bank_logo = {'name':dtl.data[0].bank_logo};
            prevState.bank_name = dtl.data[0].bank_name;
            prevState.bank_ifsc_code = dtl.data[0].bank_ifsc_code;
            prevState.bank_full_address = dtl.data[0].bank_full_address;
            prevState.bank_country = dtl.data[0].bank_country;
            prevState.state = dtl.data[0].state[0];
            prevState.city = dtl.data[0].city[0];
            prevState.other_description = dtl.data[0].other_description;
            return { ...prevState };
        });
        console.log("formData==>",formData);
    }
    }

    const citydtl = async (val) => {
        var payload = {
            "state":val
           }
        var dtl = await dispatch(city_list(payload));
        console.log(dtl);
    if (dtl.success) {
        setFormData((prevState) => {
            return { ...prevState,citylist: dtl.data};
        });
    }
    }

    const statedtl = async (val) => {
        console.log("formData.bank_country",val);
       var payload = {
        "country":val
       }
        var dtl = await dispatch(state_list(payload));
        console.log(dtl);
    if (dtl.success) {
        setFormData((prevState) => {
            return { ...prevState,statelist: dtl.data};
        });
    }
    }

    const countrydtl = async () => {
       
        var dtl = await dispatch(country_list());
        console.log(dtl);
    if (dtl.success) {
        setFormData((prevState) => {
            return { ...prevState,countrylist: dtl.data};
        });
    }
    }

    useEffect(() => {
        countrydtl();
        if (Id !== undefined) {
            
            dtlbyid();
        }
    }, [Id]);
   
    const handleFile = async (event) => {
        const file = event.target.files[0];
        //const base64 = await getBase64(file);
        setFormData((prevState) => {
            return { ...prevState,bank_logo: file};
        });
        //setLogo(file);
        //console.log(logo);
    };

    const handleChange = async(event) => {
        if (event.target.files !== null && event.target.files !== undefined ) {
            const { name, files } = event.target;
            setFormData((prevState) => {
                return { ...prevState, [name]: files[0] };
            });
        }
        else
        {
            const { name, value } = event.target;
            setFormData((prevState) => {
                return { ...prevState, [name]: value };
            });
        }
        

        if (event.target.name == 'bank_country') {
            console.log("babnkcountry=======================>>>>>");
            statedtl(event.target.value) 
            setFormData((prevState) => {
                return { ...prevState, state: -1,city:-1 };
            });
        }
        if (event.target.name == "state") {
            citydtl(event.target.value)
        }
    };


    const handleSubmit = async(event) => {
        event.preventDefault();
        console.log(formData);
        var dtl = await dispatch(bank_add(formData));
        if (dtl.success) {
            toast.warn(dtl.message);
            //window.location.reload();
            window.location.href = '/banks';
            //return navigate('/banks');
        }
           
    };


    return (
        <>
        <ToastContainer />
        <div className="bg-white py-5 pl-10 pr-16 flex justify-between items-center shadow-lg">
                <div className="flex items-center">
                    <span className="mr-5" onClick={() => navigate(-1)}>
                        <img src={LeftArrow} alt="leftarrow" className="w-12 " />
                    </span>
                    <span className="font-bold text-xl">Add new Bank</span>
                </div>
                <div className="flex gap-3 text-sm">
                    <Button type="redBorder"  onClick={() => navigate('/banks')}>
                        <Link to="/banks">
                        <span>Cancel</span>
                        </Link>
                    </Button>
                    <Button type="blueSolid" onClick={handleSubmit}>{Id !== undefined  ? 'Update' : 'Create'} </Button>
                </div>
            </div>
            <div className="w-full h-full bg-kb-light-gray bg-opacity-30 py-4 px-11">
        <div className="bg-white py-8 px-5">
            <h2 className="font-bold text-base text-[#11142D]">Bank Details</h2>
            <hr className="my-3" />
            <div className="grid grid-cols-2 gap-4">
                <InputText
                    label="Bank Name"
                    value={formData.bank_name}
                    name="bank_name"
                    onChange={handleChange}
                    placeholder="Type bank name here"
                />

                <InputFile 
                label="Bank Logo" 
                name="bank_logo" 
                val={formData.bank_logo == undefined ? '' : formData.bank_logo.name } 
                val1={formData.bank_logo == undefined ? '' : formData.bank_logo} 
                onChange={handleFile} />
                <InputText
                    label="IFSC Code"
                    value={formData.bank_ifsc_code}
                    name="bank_ifsc_code"
                    onChange={handleChange}
                    type="text"
                    placeholder="Bank IFSC code"
                />
                <InputText
                    label="Bank Address (if needed)"
                    value={formData.bank_full_address}
                    name="bank_full_address"
                    onChange={handleChange}
                    type="text"
                    placeholder="Bank location"
                />
                <InputSelect
                    label="Country"
                    value={formData.bank_country}
                    options={formData.countrylist}
                    name="bank_country"
                    onChange={handleChange}
                    placeholder="Bank country"
                    type="text"
                />
                <InputSelect
                    label="State"
                    value={formData.state}
                    options={formData.statelist}
                    name="state"
                    onChange={handleChange}
                    placeholder="Bank state"
                    type="text"
                />
                <InputSelect
                    label="City"
                    value={formData.city}
                    options={formData.citylist}
                    name="city"
                    onChange={handleChange}
                    placeholder="Bank city"
                    type="text"
                />
                 
                <div className="col-span-2">
                    <BodyTextEditor
                        // name="description"
                        label="Other Description"
                        value={formData.other_description}
                        data={formData.other_description}
                         onChange={(item) => formData.other_description = item}
                    />
                </div>
            </div>
        </div>
        </div>
        </>
    );
};

export default BankForm;
