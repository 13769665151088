import React, { useState } from 'react';
import Table from '../utils/Table';
import UserCardOffersTable from './UserCardOffersTable';

const UserCardsTable = ({ data }) => {
    const [showOffersOfId, setShowOffersOfId] = useState(null);

    const handleNavigate = () => {
        setShowOffersOfId(null);
    };
    const CARDS_COLUMNS = [
        {
            Header: 'Card Name',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        src={tableProps.row.original.card_image}
                        className="w-11 rounded-full mr-4"
                        alt={tableProps.row.original.card_name}
                    />
                    <span>{tableProps.row.original.card_name}</span>
                </div>
            )
        },

        {
            Header: 'Bank name',
            Cell: (tableProps) => (tableProps.row.original.bank_name)
        },
        {
            Header: 'Card Expiry Date',
            Cell: (tableProps) => (tableProps.row.original.card_exp_dt)
        },
        {
            Header: 'Payment due date',
            Cell: (tableProps) => (tableProps.row.original.payment_due_dt)
        },
        {
            Header: 'Available Offers',
            Cell: (tableProps) => (
                <button
                    className="text-kb-light-blue"
                    onClick={() => setShowOffersOfId(tableProps.row.original.card_id)}
                >
                    {tableProps.row.original.offercount} Offers
                </button>
            )
        }
    ];

    if (showOffersOfId) {
        return <UserCardOffersTable data={data} onBack={handleNavigate} />;
    } else {
        return (
            <div className="bg-white py-5 px-6">
                <h2 className="font-semibold text-base text-[11142D] ">Total Payment Cards</h2>
                <hr className="my-4" />
                <Table columnData={CARDS_COLUMNS} rowData={data} />
            </div>
        );
    }
};

export default UserCardsTable;
