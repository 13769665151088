import { OutletIcon, VerticalDotsIcon } from '../../assets';
import React,{ useState } from 'react';

const ActionCard = ({
    active, // active state of row
    onClick, /// toggles closing action card
    actionData = [{ title: ':(  found', icon: OutletIcon, action: () => console.log('Hello') }]
    
}) => {
    const [actions, setActions] = useState(false);
    return (
        <div className="pl-3 ">
            <div className="relative w-8 h-8">
                <button className="hover:bg-gray-200 w-8 h-8 flex items-center justify-center rounded-full py-3"
                     onMouseEnter={() => setActions(true)}
                     onMouseLeave={() => setActions(false)}
                >
                    <VerticalDotsIcon />
                {actions && (
                    <div className="items-start top-8 -left-16 flex py-2 w-36 flex-col absolute bg-white z-40 shadow-xl border rounded-lg border-kb-light-gray text-kb-dark-gray">
                        {actionData.map((item) => (
                            <button
                                key={item.title}
                                className="px-4 py-2 w-full rounded-md flex gap-3 items-center hover:bg-kb-light-blue hover:text-kb-light-blue hover:bg-opacity-20"
                                onClick={item.action}
                            >
                                <item.icon />
                                <span className="text-xs">{item.title}</span>
                            </button>
                        ))}
                    </div>
                )}
                </button>

            </div>
        </div>
    );
};

export default ActionCard;
