import {
    BANK_ADD,
    BANK_LIST,
    CLEAR_ERRORS,
    BANK_REQUEST
  } from "../constants/bankConstants";
  import axios from "axios";
  import {HOSTNAME} from './APIConstant';
  import Cookies from 'universal-cookie';
  const cookies = new Cookies();

export const get_bank_list = (payload) => async () => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.post(
        HOSTNAME +`/banks/api/v1/bankList`,
        payload,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      return (error.response.data.message);
    }
  };

  export const bank_add = (bankdtl) => async (dispatch) => {
    try {
  
      //const config = { headers: { "Content-Type": "application/json" } };
      const config = { headers: { "Content-Type": "multipart/form-data",'token':cookies.get('token') } };
  
      console.log(bankdtl);
      if (bankdtl.id !== undefined) {
        const { data } = await axios.post(
          HOSTNAME + `/banks/api/v1/update`,
          bankdtl,
          config
        );
        console.log(data);
      return (data);
      }
      else
      {
        console.log("else");
        const { data } = await axios.post(
          HOSTNAME + `/banks/api/v1/addbank`,
          bankdtl,
          config
        );
        console.log(data);
      return (data);
      }
      
        
    } catch (error) {
      dispatch({ type: BANK_LIST, payload: error.response.data.message });
    }
  };

  export const getbankdtlbyid = (id) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      console.log(id);
      
      const { data } = await axios.post(
        HOSTNAME + `/banks/api/v1/bankdtlbyid`,
        id,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      dispatch({ type: BANK_LIST, payload: error.response.data.message });
    }
  };

  export const bank_delete = (id) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      console.log(id);
        const { data } = await axios.post(
          HOSTNAME + `/banks/api/v1/delete_bank`,
          id,
          config
        );
        console.log(data);
      return (data);
        
    } catch (error) {
      dispatch({ type: BANK_LIST, payload: error.response.data.message });
    }
  };

  // Clearing Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };

  export const city_list = (payload) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.post(
        HOSTNAME + `/banks/api/v1/getcity`,
        payload,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      return error.message
    }
  };

  export const state_list = (payload) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
      console.log("this dat=>",payload);
      const { data } = await axios.post(
        HOSTNAME + `/banks/api/v1/getstate`,
        payload,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      return error.message
    }
  };

  export const country_list = () => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.get(
        HOSTNAME + `/banks/api/v1/getcountry`,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      return error.message
    }
  };


  export const getautobank_list = () => async (dispatch) => {
    try {
      dispatch({ type: BANK_REQUEST });
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.get(
        HOSTNAME + `/banks/api/v1/getallautobank`,
        config
      );
        console.log(data);
      dispatch({ type: BANK_LIST, payload: data });
      return (data);
    } catch (error) {
      return (error.response.data.message);
    }
  };

  export const get_bank_count = () => async (dispatch) => {
    try {
      console.log("========>");
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.get(
        HOSTNAME +`/banks/api/v1/bankcount`,
        config
      );
        console.log(data);
      return (data.data);
    } catch (error) {
      return (error.response.data.message);
    }
  };