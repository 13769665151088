import React,{ useState,useEffect } from 'react';
import Button from '../utils/Button';
import BodyTextEditor from '../utils/TextEditor';
import { useNavigate } from 'react-router-dom';
import {useDispatch,useSelector} from "react-redux";
import {add_data,get_dtl} from '../../action/staticAction';
const Privacy = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

   
    const [text_dtl, setTextdtl] = useState('');

    const handleFile = async (event) => {
        setTextdtl(event)
    };

    const get_data = async () =>{
        var dtl = await dispatch(get_dtl());
        setTextdtl(dtl.privacy[0].text)
        return dtl;
    }
    useEffect(() => {
        get_data();
        
    }, [])

    const handleSubmit = async() => {
        const bankdtl = {
            text_dtl :  text_dtl,
            page:"privacy_policy"
        };
      
        var dtl = await dispatch(add_data(bankdtl));
        if (dtl.success) {
            return navigate('/static');
        }
    };

    return (
        <div className="relative">
            <div className="sticky top-0 z-50 bg-white shadow-md py-8 px-12 flex justify-between items-center">
                <h2 className="text-3xl font-bold">Privacy</h2>
                <Button type="blueSolid" onClick = {handleSubmit}>Update Now</Button>
            </div>
            <div className="py-8 px-12 shadow-inner-xl bg-kb-light-gray bg-opacity-30">
                <BodyTextEditor label={'Privacy'} data={text_dtl}  onChange={handleFile}/>
            </div>
        </div>
    );
};

export default Privacy;
