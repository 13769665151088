import React, { useState,useEffect }  from 'react';
import { Outlet } from 'react-router-dom';
import { BlueSphere } from '../assets';
import Header from '../components/utils/Header';
import {useDispatch} from "react-redux";
import { get_user_count } from '../action/userAction';

const UsersLayout = ({ children }) => {
    
    return (
        <>
            <Outlet />
        </>
    );
};

export default UsersLayout;
