import { createStore , combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  loginReducer,
} from "./reducers/loginReducer";

import {
  bankReducer
} from "./reducers/bankReducer";

import {
  categoryReducer
} from "./reducers/categoryReducer";



const reducer = combineReducers({
    user: loginReducer,
    bank:bankReducer,
    category:categoryReducer
  });
  
  let initialState = {};
  
  const middleware = [thunk];
  
  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  
  export default store;
  