import React from 'react';

function BankIcon({ color = '#6e6b7b' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.296"
            height="14.299"
            viewBox="0 0 14.296 14.299"
        >
            <g id="Group_23043" data-name="Group 23043" transform="translate(-156.388 -287.286)">
                <path
                    id="Path_49617"
                    data-name="Path 49617"
                    d="M169.4,293.148v5.793c.015.013.026.033.042.036a1.338,1.338,0,0,1,1.236,1.56c0,.232,0,.465,0,.7a.325.325,0,0,1-.35.349c-.065,0-.13,0-.2,0H156.942c-.481,0-.547-.066-.548-.545,0-.251,0-.5,0-.753a1.28,1.28,0,0,1,1.072-1.272c.15-.027.224-.063.224-.238-.006-1.822,0-3.645,0-5.467,0-.044-.008-.089-.015-.162-.289,0-.565,0-.841,0-.326-.005-.433-.113-.435-.438,0-.372-.019-.745.009-1.115a.547.547,0,0,1,.211-.38q3.14-1.887,6.3-3.747a1.169,1.169,0,0,1,1.248,0q3.154,1.864,6.3,3.747a.547.547,0,0,1,.212.38c.028.379.013.762.009,1.143,0,.291-.114.4-.406.41S169.7,293.148,169.4,293.148Zm-12.348-.673h12.971c0-.227.012-.439-.007-.649a.32.32,0,0,0-.141-.209q-3-1.792-6.008-3.572a.618.618,0,0,0-.663,0q-3.006,1.783-6.008,3.572a.292.292,0,0,0-.138.183C157.041,292.02,157.051,292.241,157.051,292.474Zm12.971,8.447c0-.147,0-.266,0-.385,0-.718-.186-.9-.911-.9H157.93a2.546,2.546,0,0,0-.279,0,.584.584,0,0,0-.567.458,4.614,4.614,0,0,0-.011.825Zm-9.7-7.757v5.8h1.907v-5.8Zm4.528,0v5.8h1.916v-5.8Zm-1.948,0v5.808h1.269v-5.808Zm-3.281,5.81v-5.8h-1.25v5.8Zm7.841-5.82v5.82h1.249v-5.82H167.46Z"
                    transform="translate(0 0)"
                    fill={color}
                />
                <path
                    id="Path_49618"
                    data-name="Path 49618"
                    d="M263.94,323.744a1.293,1.293,0,1,1-1.3-1.285A1.285,1.285,0,0,1,263.94,323.744Zm-1.3-.632a.647.647,0,0,0-.643.637.65.65,0,1,0,1.3.015A.646.646,0,0,0,262.64,323.112Z"
                    transform="translate(-99.111 -33.211)"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default BankIcon;
