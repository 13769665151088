import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { cat_add, getcatdtlbyid } from '../../action/categoryAction';
import { LeftArrow } from '../../assets';
import CategoryForm from '../../components/Category/CategoryForm';
import Button from '../../components/utils/Button';
import {useDispatch,useSelector} from "react-redux";

const AddCategory = () => {
    const Id = window.location.href.split('/')[5];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        image: '',
        other_description: '',
        Id:'',
        preview:''
    });

    const dtlbyid = async () => {
        const data={
            Id:Id
        }
        var dtl = await dispatch(getcatdtlbyid(data));
        console.log(dtl);
    if (dtl.success) {
            setFormData((prevState) => {
                console.log(prevState);
                prevState.name = dtl.data[0].name;
                prevState.other_description = dtl.data[0].other_description;
                prevState.image = ({'name':dtl.data[0].image});
                prevState.Id = Id;
                return { ...prevState };
            });
    }
    }

    useEffect(() => {
        if (Id !== undefined) {
            
            dtlbyid();
        }
    }, [Id]);
   

    const handleChange = (event) => {
        if (event.target.files !== null) {
            const { name, files } = event.target;
            console.log(URL.createObjectURL(event.target.files[0]));
            console.log(event.target.file);
            setFormData((prevState) => {
                return { ...prevState, [name]: (files[0]) };
            });
        }
        else
        {
            const { name, value } = event.target;
            setFormData((prevState) => {
                return { ...prevState, [name]: value };
            });
        }
        
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        console.log(formData);
        
            var dtl = await dispatch(cat_add(formData));
            if (dtl.success) {
                return navigate('/category');
            }
    };

    return (
        <div>
            <div className="bg-white sticky top-0 z-50 py-5 pl-10 pr-16 flex justify-between items-center shadow-lg">
                <div className="flex items-center">
                    <span className="mr-5" onClick={() => navigate(-1)}>
                        <img src={LeftArrow} alt="leftarrow" className="w-12 " />
                    </span>
                    <span className="font-bold text-xl">
                        {Id ? 'Update Category' : 'New Category'}
                    </span>
                </div>
                <div className="flex gap-3 text-sm">
                    <Button type="redBorder" onClick={() => navigate(-1)}>
                        <span>Cancel</span>
                    </Button>
                    <Button type="blueSolid" onClick={handleSubmit}>
                        {Id ? 'Update' : 'Create'}
                    </Button>
                </div>
            </div>
            <div className="w-full h-full bg-kb-light-gray bg-opacity-30 py-4 px-11">
                <CategoryForm values={formData} onChange={handleChange} />
            </div>
        </div>
    );
};

export default AddCategory;
