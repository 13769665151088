import React from 'react';
import { InputFile, InputText } from '../utils/Input';
import BodyTextEditor from '../utils/TextEditor';

const OutletOfferForm = ({ values, onChange }) => {
    return (
        <div className="bg-white py-8 px-6">
            <h2 className=" font-semibold text-base ">Outlet Details</h2>
            <hr className="my-4" />
            <form className="grid grid-cols-2 gap-4">
                <InputText
                    name="title"
                    label="Outlet/Brand"
                    value={values.title}
                    onChange={onChange}
                />
                <InputFile
                    name="logo"
                    label="Upload Logo"
                    value={values.logo}
                    onChange={onChange}
                />
                <InputText name="logo" label="Country" value={values.logo} onChange={onChange} />
                <InputText name="logo" label="Location" value={values.logo} onChange={onChange} />
                <InputText
                    name="logo"
                    label="Outlet Latitude"
                    value={values.logo}
                    onChange={onChange}
                />
                <InputText
                    name="logo"
                    label="Outlet Longitude"
                    value={values.logo}
                    onChange={onChange}
                />
                <div className="col-span-2">
                    <BodyTextEditor
                        // name="description"
                        label="Description"
                        value={values.description}
                        // onChange={onChange}
                    />
                </div>
            </form>
        </div>
    );
};

export default OutletOfferForm;
