
  import axios from "axios";
  import {HOSTNAME} from './APIConstant';
  import Cookies from 'universal-cookie';
  const cookies = new Cookies();

  export const outlet_list = () => async (dispatch) => {
      try {
    
        const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
    
        const { data } = await axios.get(
          HOSTNAME + `/api/v1/outlet/getall`,
          config
        );
          console.log(data);
        return (data);
      } catch (error) {
        dispatch({ payload: error.response.data.message });
      }
    };
  

    export const Outlet_Add = (outletdtl) => async (dispatch) => {
      try {
    
        const config = { headers: { "Content-Type": "multipart/form-data",'token':cookies.get('token') } };
    
        if (outletdtl.Id !== '') {
          const { data } = await axios.post(
            HOSTNAME + `/api/v1/outlet/update`,
            outletdtl,
            config
          );
            console.log(data);
          return (data);
        }
        else{
          const { data } = await axios.post(
            HOSTNAME + `/api/v1/outlet/add`,
            outletdtl,
            config
          );
            console.log(data);
          return (data);
        }
      } catch (error) {
        return error.message;
      }
    };

    export const GetOutletDtlById = (id) => async (dispatch) => {
      try {
    
        const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
    
        console.log(id);
        
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/outlet/outletdtlbyid`,
          id,
          config
        );
          console.log(data);
        return (data);
      } catch (error) {
        return error.message;
      }
    };
   

    export const Outlet_Delete = (outletdtl) => async (dispatch) => {
      try {
        const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
    
       
          const { data } = await axios.post(
            HOSTNAME + `/api/v1/outlet/delete`,
            outletdtl,
            config
          );
            console.log(data);
          return (data);
      } catch (error) {
        return error.message;
      }
    };

    export const GetOutletDtlByIdForView = (id) => async (dispatch) => {
      try {
    
        const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
    
        console.log(id);
        
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/outlet/outletdtlbyidforview`,
          id,
          config
        );
          console.log(data);
        return (data);
      } catch (error) {
        return error.message;
      }
    };

    export const get_outlet_count = () => async (dispatch) => {
      try {
        const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
    
        const { data } = await axios.get(
          HOSTNAME +`/api/v1/outletcount`,
          config
        );
          console.log(data);
        return (data.data);
      } catch (error) {
        return (error.response.data.message);
      }
    };