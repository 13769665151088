import React , { useState }from 'react';
import { InputDatePicker, InputFile, InputSelect, InputText } from '../utils/Input';
import BodyTextEditor from '../utils/TextEditor';

const OfferForm = ({ values, onChange,autodata ,allautodata}) => {
    var apply_list =[
        {'name':'online/offline','value':'online/offline'},
        {'name':'offline','value':'offline'},
        {'name':'online','value':'online'}
    ]
    //const [Desc, setDescription] = useState(values.other_text );
    // const  SetDesc = (event) => {
    //     setDescription(event);
    //     values.other_text = event;
    // }

    // const [ShortDesc, setShortDescription] = useState(values.short_desc );
    // const  SetShortDesc = (event) => {
    //     setShortDescription(event);
    //     values.short_desc = event;
    // }




    return (
        <div>
            <form className="grid grid-cols-2 gap-4">
            <InputSelect
                    name="apply"
                    label="Apply"
                    value={values.apply}
                    onChange={onChange}
                    options={apply_list}
                />
                <InputFile
                    name="offer_logo"
                    label="Image or Logo"
                    //value={values.image}
                    val={values.offer_logo.name}
                    val1={values.offer_logo}
                    onChange={onChange}
                />
                <InputText
                    name="offer_tag"
                    label="Offertag"
                    value={values.offer_tag}
                    onChange={onChange}
                />
                 <InputDatePicker
                    name="offer_expiry_date"
                    label="Expiry Date"
                    value={values.offer_expiry_date}
                    onChange={onChange}
                    min={new Date()}
                />
                
                 <InputSelect
                    name="bank_id"
                    label="Offer on Bank"
                    value={values.bank_id}
                    onChange={onChange}
                    options={values.banklist}
                />
                 <InputSelect
                    name="card_type"
                    label="Card Type"
                    value={values.card_type}
                    onChange={onChange}
                    options={values.cardtypelist}
                />
                 <InputSelect
                    name="card_id"
                    label="offer on Card Type"
                    value={values.card_id}
                    onChange={onChange}
                    options={values.bankcardlist}
                    all = {true}
                />
                <InputSelect
                    name="category_id"
                    label="Category"
                    value={values.category_id}
                    onChange={onChange}
                    options={values.categorylist}
                />
                <InputSelect
                    name="outlet_id"
                    label="Outlet"
                    value={values.outlet_id}
                    onChange={onChange}
                    options={values.outletlist}
                />
                <InputSelect
                    name="country"
                    label="Country"
                    value={values.country}
                    onChange={onChange}
                    options={values.countrylist}
                />
               
                <InputText
                    name="location"
                    label="Location"
                    value={values.location}
                    onChange={onChange}
                />
                <InputText
                    name="latitude"
                    label="Outlet_latitude"
                    value={values.latitude}
                    onChange={onChange}
                />
                 <InputText
                    name="longitude"
                    label="Outlet_longitude"
                    value={values.longitude}
                    onChange={onChange}
                />
                <InputText
                    name="discount"
                    label="Discount"
                    value={values.discount}
                    onChange={onChange}
                />
                <InputText
                    name="price"
                    label="Price"
                    value={values.price}
                    onChange={onChange}
                />
                 <InputSelect
                    name="offertype"
                    label="Offer Type"
                    value={values.offertype}
                    onChange={onChange}
                    options={values.offertypelist}
                />
                 {/* <div className="col-span-2 col-xl-12">
                    <BodyTextEditor
                        // name="description"
                        label="Short Description"
                        value={values.short_desc}
                        data={values.Id !== '' ? values.short_desc : ShortDesc}
                         onChange={SetShortDesc}
                    />
                </div> */}
                <InputText
                    name="short_desc"
                    label="Short Description"
                    value={values.short_desc}
                    onChange={onChange}
                />
                <div className="col-span-2 col-xl-12">
                    <BodyTextEditor
                        // name="description"
                        label="Long Description"
                        value={values.other_text}
                        data={values.other_text}
                        onChange={(item) => values.other_text = item}
                    />
                </div>
            </form>
        </div>
    );
};

export default OfferForm;
