import React from 'react';

const Welcome = (data) => {
    return (
        <div className="text-[#11142D]">
            <h2 className="text-2xl font-medium">Hi {data.data !== undefined ? data.data.name[0].name : ''} </h2>
            <h2 className="text-5xl font-semibold">Welcome 👋🏼</h2>
        </div>
    );
};

export default Welcome;
