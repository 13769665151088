import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { offer_add, getofferdtlbyid, getautodtl } from '../../action/offerAction';
import { LeftArrow } from '../../assets';
import OfferForm from '../../components/Offers/OfferForm';
import Button from '../../components/utils/Button';
import {useDispatch,useSelector} from "react-redux";
import {ToastContainer,toast} from 'react-toastify';
import { format } from 'date-fns';

const AddOffer = () => {
    const Id = window.location.href.split('/')[5];
    console.log(Id);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        offer_tag: '',
        offer_logo: '',
        offer_expiry_date: new Date(),
        Id:'',
        other_text:'',
        short_desc:'',
        price:'',
        discount:'',
        category_id:'',
        outlet_id:'',
        card_id:'',
        bank_id:'',
        country:'',
        location:'',
        latitude:'',
        longitude:'',
        banklist:[],
        categorylist:[],
        outletlist:[],
        bankcardlist:[],
        offertypelist:[],
        countrylist:[],
        cardtypelist:[],
        offertype:'',
        apply:'',
        card_type:''
    });

    const dtlbyid = async () => {
        const data={
            Id:Id
        }
        var dtl = await dispatch(getofferdtlbyid(data));
    if (dtl.success) {
            setFormData((prevState) => {
                prevState.offer_tag = dtl.data.offer_tag;
                prevState.offer_expiry_date = new Date(dtl.data.offer_expiry_date.split('T')[0]);
                prevState.offer_logo = ({'name':dtl.data.offer_logo});
                prevState.Id = Id;
                prevState.other_text = dtl.data.other_text;
                prevState.short_desc = dtl.data.short_desc;
                prevState.price = dtl.data.price;
                prevState.discount = dtl.data.discount;
                prevState.category_id = dtl.data.category_id;
                prevState.outlet_id = dtl.data.outlet_id;
                prevState.card_id = dtl.data.card_id;
                prevState.bank_id = dtl.data.bank_id;
                prevState.country = dtl.data.country;
                prevState.location = dtl.data.location;
                prevState.latitude = dtl.data.latitude;
                prevState.longitude = dtl.data.longitude;
                prevState.offertype = dtl.data.offertype;
                prevState.apply = dtl.data.apply;
                prevState.card_type = dtl.data.card_type;
                return { ...prevState };
            });
    }
    }
    var allautodata = [];

    useEffect(() => {
        autodata();
        if (Id !== undefined) {
            
            dtlbyid();
        }
    }, [Id]);

    const autodata = async () => {
        
        var dtl = await dispatch(getautodtl());
        allautodata.push({'banklist':dtl.bank,'outletlist':dtl.outlet,'categorylist':dtl.category,'cardtypelist':dtl.cardtypels})
        setFormData((prevState) => {
            return { ...prevState, banklist:dtl.bank,categorylist:dtl.category,outletlist:dtl.outlet,bankcardlist:dtl.bankcard,offertypelist: dtl.offertype,countrylist: dtl.country,cardtypelist:dtl.cardtypels};
        });
    }


   
    
   

    const handleChange = async(event) => {
        if(event.target === undefined)
        {
            setFormData((prevState) => {
                prevState.offer_expiry_date = new Date(event);
                return { ...prevState };
            });
        }
        if (event.target.files !== null && event.target.files !== undefined ) {
            const { name, files } = event.target;
            setFormData((prevState) => {
                return { ...prevState, [name]: files[0] };
            });
        }
        else
        {
            const { name, value } = event.target;
            setFormData((prevState) => {
                return { ...prevState, [name]: value };
            });
        }
        
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        console.log(formData);
        if (formData.offer_tag.trim().length > 0) {
            var dtl = await dispatch(offer_add(formData));
            if (dtl.success) {
                return navigate('/offers');
            }
            return false;
        }
        else{
            if (formData.offer_tag.trim().length === 0) {
                toast.error("Plase Enter Offer Tag.");
                return false;
            }
           
        }
           
    };

    return (
        <div>
            <ToastContainer />
            <div className="bg-white sticky top-0 z-50 py-5 pl-10 pr-16 flex justify-between items-center shadow-lg">
                <div className="flex items-center">
                    <span className="mr-5" onClick={() => navigate(-1)}>
                        <img src={LeftArrow} alt="leftarrow" className="w-12 " />
                    </span>
                    <span className="font-bold text-xl">Universal Offers upload</span>
                </div>
                <div className="flex gap-3 text-sm">
                    <Button type="redBorder" onClick={() => navigate(-1)}>
                        <span>Cancel</span>
                    </Button>
                    <Button type="blueSolid" onClick={handleSubmit}>
                       {Id !== undefined  ? 'Update' : 'Create'} 
                    </Button>
                </div>
            </div>
            <div className="w-full h-full bg-kb-light-gray bg-opacity-30 py-4 px-11">
                <OfferForm values={formData} onChange={handleChange} autodata = {allautodata}/>
            </div>
        </div>
    );
};

export default AddOffer;
