import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BanksLayout from './layouts/BanksLayout';
import CategoryLayout from './layouts/CategoryLayout';
import Layout from './layouts/Layout';
import OffersLayout from './layouts/OffersLayout';
import OutletsLayout from './layouts/OutletsLayout';
import UsersLayout from './layouts/UsersLayout';
import Application from './pages/Application';
import AddBank from './pages/Banks/AddBank';
import Bank from './pages/Banks/Bank';
import Banks from './pages/Banks/Banks';
import Card from './pages/Banks/Card';
import UploadCard from './pages/Banks/UploadCard';
import UploadOffer from './pages/Banks/UploadOffer';
import AddCategory from './pages/Category/AddCategory';
import Category from './pages/Category/Category';
import Login from './pages/Login';
import Outlet from './pages/Outlets/Outlet';
import Outlets from './pages/Outlets/Outlets';
import OutletOffers from './pages/Outlets/OutletOffers';
import AddOutletOffer from './pages/Outlets/AddOutletOffer';
import AddOutlet from './pages/Outlets/AddOutlet';
import Overview from './pages/Overview';
import Static from './pages/Static';
import User from './pages/Users/User';
import Users from './pages/Users/Users';
import Offers from './pages/Offers/Offers';
import CardsOffers from './pages/Offers/CardsOffers';
import BankExcitingOffers from './pages/Offers/BankExcitingOffers';
import BrandExcitingOffers from './pages/Offers/BrandExcitingOffers';
import OutletsOffers from './pages/Offers/OutletsOffers';
import BanksOffers from './pages/Offers/BanksOffers';

import AddOffer from './pages/Offers/AddOffer.jsx';
import Logout from './pages/Logout';
import OutletByUser from './pages/OutletByUser/outlet_by_user.jsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import BankOffers from './components/Banks/BankOffers';
const cookies = new Cookies();
// function App() {
//   useEffect(()=>{
//     webFont.load({
//       google:{
//         families:["Roboto","Droid Sans","Chilanka"]
//       },
//     });
//   })

//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Login/>} />
//         <Route path="/dashboard" element={<Dashboard/>} />
//         </Routes>
//    </Router>
//   );
// }

//config

// export default App;

export default function App() {
    if (cookies.get('token') === undefined) {
        console.log("if");
        return (
            <Routes>
                <Route path='*' element={<Login />} />
            </Routes>
        )
    }
    return (
        <Routes>
            <Route index element={<Login />} />
            <Route path="/" element={<Layout />}>
                <Route path="overview" element={<Overview />} />


                <Route path="banks" element={<BanksLayout />}>
                    <Route path="bankadd" element={<AddBank />} />
                    <Route path="editbank/:id" element={<AddBank />} />
                    <Route index element={<Banks />} />
                    <Route path=":bankId">
                        <Route index element={<Bank />} />
                        <Route path="upload-card" element={<UploadCard />} />
                        <Route path="edit-upload-card/:id" element={<UploadCard />} />
                        <Route path=":cardId">
                            <Route index element={<Card />} />
                            <Route path="new-offer" element={<UploadOffer />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="bankOffers/:id" element={<BankOffers />} />

                <Route path="users" element={<UsersLayout />}>
                    <Route index element={<Users />} />
                    <Route path=":id" element={<User />} />
                </Route>

                <Route path="outlets" element={<OutletsLayout />}>
                    <Route index element={<Outlets />} />
                    <Route path=":id">
                        <Route index element={<Outlet />} />
                        <Route path="offers" element={<OutletOffers />} />
                        <Route path="offers/add" element={<AddOutletOffer />} />
                    </Route>
                    <Route path="add" element={<AddOutlet />} />
                    <Route path="edit/:id" element={<AddOutlet />} />
                </Route>

                <Route path="applications" element={<Application />} />

                <Route path="offers" element={<OffersLayout />}>
                    <Route index element={<Offers />} />
                    <Route path="bank-offers" element={<BanksOffers />} />
                    <Route path="cards-offers" element={<CardsOffers />} />
                    <Route path="exciting-offers-bank" element={<BankExcitingOffers />} />
                    <Route path="exciting-offers-brand" element={<BrandExcitingOffers />} />
                    <Route path="outlet-offers" element={<OutletsOffers />} />
                </Route>

                <Route path="offers" >
                    <Route path="add" element={<AddOffer />} />
                    <Route path="edit/:id" element={<AddOffer />} />
                </Route>

                <Route path="category">
                    <Route index element={<Category />} />
                    <Route path="add" element={<AddCategory />} />
                    <Route path="edit/:id" element={<AddCategory />} />
                </Route>

                <Route path="static" element={<Static />}></Route>
                <Route path="outlet_by_user" element={<OutletsLayout />}>
                    <Route index element={<OutletByUser />} />
                </Route>
                <Route path="logout" element={<Logout />}></Route>

                <Route
                    path="*"
                    element={
                        <main style={{ padding: '1rem' }}>
                            <p>There's nothing here!</p>
                        </main>
                    }
                />
            </Route>
        </Routes>
    );



}