import React, { useState } from 'react';
import { InputFile, InputText } from '../utils/Input';
import BodyTextEditor from '../utils/TextEditor';

const OutletForm = ({ values, onChange, handleImage }) => {
    // const [Desc, setDescription] = useState(values.description);
    
    // const  SetDesc = (event) => {
    //     setDescription(event);
    //     values.description = event;
    // }

    return (
        <div className="bg-white py-8 px-6">
            <h2 className=" font-semibold text-base ">Outlet Details</h2>
            <hr className="my-4" />
            <form className="grid grid-cols-2 gap-4">
                <InputText
                    name="outletname"
                    label="Outlet/Brand"
                    value={values.outletname}
                    onChange={onChange}
                />
                <InputFile
                    name="logo"
                    val={values.logo.name}
                    val1={values.logo}
                    label="Upload Logo"
                    onChange={onChange}
                />
                <InputText
                    name="country"
                    label="Country"
                    value={values.country}
                    onChange={onChange}
                />
                <InputText
                    name="location"
                    label="Location"
                    value={values.location}
                    onChange={onChange}
                />
                <InputText
                    name="outlet_latitude"
                    label="Outlet Latitude"
                    value={values.outlet_latitude}
                    onChange={onChange}
                />
                <InputText
                    name="outlet_longitude"
                    label="Outlet Longitude"
                    value={values.outlet_longitude}
                    onChange={onChange}
                />
                <div className="col-span-2">
                    <BodyTextEditor
                        // name="description"
                        label="Description"
                        value={values.description}
                        data={values.description}
                        onChange={(item) => values.description = item}
                    />
                </div>
            </form>
        </div>
    );
};

export default OutletForm;
