import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DownloadCloud, UploadCloud } from '../../assets';
import Button from '../../components/utils/Button';
import Table from '../../components/utils/Table';
import BankLayout from '../../layouts/BankLayout';
import BANK_JSON from '../../sitedata/bank.json';
import { bankcard_list } from '../../action/bancardAction';
import { useDispatch } from "react-redux";
import UploadCard from './UploadCard';
import { BANK_ADD, BANK_LIST } from '../../constants/bankConstants';
import CardTableActions from '../../components/Banks/CardTableAction';


const Bank = (props) => {
    const location = useLocation();
    var bank_all_dtl = location.state;

    const const_url = window.location.href.split('/');
    const Id = const_url[const_url.length - 1]
    const [card_list, setBankCard] = useState([]);
    const COLUMNS = [
        {
            Header: 'Logo',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        src={tableProps.row.original.card_image}
                        className="w-16 rounded-lg mr-4"
                        alt={' '}
                    />
                    <Link to={`${location.pathname}/` + tableProps.row.original._id} state={bank_all_dtl === null ? tableProps.row.original.bank_id : bank_all_dtl}>
                        <span>{tableProps.row.original.card_name}</span>
                    </Link>
                </div>
            )
        },
        {
            Header: 'Card Type',
            Cell: (tableProps) => tableProps.row.original.card_type[0].name
        },
        {
            Header: 'Category Type',
            Cell: (tableProps) => tableProps.row.original.category.length === 0 ? '' : tableProps.row.original.category[0].name + ' Credit Card'
        },
        {
            Header: 'In App Card Holder',
            Cell: (tableProps) => tableProps.row.original.holders.length + ' Card Holders'
        },
        {
            Header: 'Offers',
            Cell: (tableProps) => tableProps.row.original.offers.length + ' Offers'
        },
        {
            Header: 'Status',
            Cell: (tableProps) => tableProps.row.original.is_active.toString()
        },
        {
            Header: 'Card Applications',
            Cell: (tableProps) => (
                <span className="flex py-4 text-kb-light-blue">
                    <span>{tableProps.row.original.applications.length} Application</span>
                    <img src={DownloadCloud} alt="img" className="w-4 object-contain ml-1" />
                </span>
            )
        },
        { Header: 'Actions', Cell: (tableProps) => tableProps.row.original.is_active ? <CardTableActions data={{ 'card_id': tableProps.row.original._id, 'bank_id': tableProps.row.original.bank_id }} /> : '' }
    ];


    if (card_list.status == undefined) {

        BankCardTabledata(Id).then(data => {
            console.log(data);
            setBankCard(data);
        });
    }

    // if (card_list !== undefined && card_list.data.length != 0) {
    //     bank_all_dtl = card_list.data[0].bank_id;
    // }
    console.log(card_list);

    return (
        <BankLayout data={bank_all_dtl === null ? card_list.length > 0 ? card_list.data[0].bank_id : card_list.bank_dtl && card_list.bank_dtl[0] : bank_all_dtl}
            actions={
                <div className="flex gap-3 text-sm">
                    <Button type="blueBorder">
                        <img src={UploadCloud} alt="upload_logo" className="mr-2" />
                        Bulk Cards Upload
                    </Button>
                    <Link to={'/banks/' + Id + '/upload-card'} state={bank_all_dtl === null ? card_list.length > 0 ? card_list.data[0].bank_id : card_list.bank_dtl && card_list.bank_dtl[0] : bank_all_dtl}>
                        <Button type="blueSolid" >Upload Card </Button>
                    </Link>
                </div>
            }
        >
            <div className="py-5 pl-10 pr-16 shadow-inner-xl">
                <Table columnData={COLUMNS} rowData={card_list.data !== undefined && card_list.data.length > 0 ? card_list.data : []} />
            </div>
        </BankLayout>
    );
};

const BankCardTabledata = async (Id) => {
    const dispatch = useDispatch();
    var dtl = {
        'bank_id': Id
    }
    var app = await dispatch(bankcard_list(dtl));
    return app;
}

export default Bank;
