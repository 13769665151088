import React from 'react';

function ApplicationIcon({ color = '#6e6b7b' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.528"
            height="14.84"
            viewBox="0 0 13.528 14.84"
        >
            <g id="Group_22629" data-name="Group 22629" transform="translate(0.5 0.5)">
                <path
                    id="Stroke_1"
                    data-name="Stroke 1"
                    d="M5.428.5H0"
                    transform="translate(3.568 9.629)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
                <path
                    id="Stroke_2"
                    data-name="Stroke 2"
                    d="M5.428.5H0"
                    transform="translate(3.568 6.482)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
                <path
                    id="Stroke_3"
                    data-name="Stroke 3"
                    d="M2.071.5H0"
                    transform="translate(3.568 3.342)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
                <path
                    id="Stroke_4"
                    data-name="Stroke 4"
                    d="M9.14,0,3.36,0A3.2,3.2,0,0,0,0,3.463v6.913A3.2,3.2,0,0,0,3.387,13.84l5.78,0a3.2,3.2,0,0,0,3.36-3.461V3.463A3.2,3.2,0,0,0,9.14,0Z"
                    transform="translate(0 0)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
            </g>
        </svg>
    );
}

export default ApplicationIcon;
