  import axios from "axios";
  import {HOSTNAME} from './APIConstant';
  import Cookies from 'universal-cookie';
  const cookies = new Cookies();

  export const add_data = (dtl) => async (dispatch) => {
    try {
        const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
      //const config = { headers: { "Content-Type": "multipart/form-data" } };
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/content/` + dtl.page,
          dtl,
          config
        );
        console.log(data);
      return (data);
      
      
        
    } catch (error) {
        return error.response.data.message;
    }
  };

  export const get_dtl = () => async () => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      
      const { data } = await axios.get(
        HOSTNAME + `/api/v1/getcontent`,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
        return error.response.data.message;
    }
  };

  // export async function  get_dtl () {
  //   try {
  
  //     const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      
  //     const { data } = await axios.get(
  //       HOSTNAME + `/api/v1/getcontent`,
  //       config
  //     );
  //       console.log(data);
  //     return (data);
  //   } catch (error) {
  //       return error.response.data.message;
  //   }
  // };
