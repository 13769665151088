import React from 'react';
import BankTable from '../../components/Banks/BankTable';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Banks = () => {
      
    return (
        <>
            <div className="pl-10 pr-16 py-5">
                <ToastContainer />
                <BankTable />
            </div>
        </>
    );
};

export default Banks;
