import React from 'react';

const Button = (props) => {
    const config = {
        redBorder: 'border text-kb-light-red border-kb-light-red',
        blueBorder: 'border text-kb-light-blue border-kb-light-blue',
        blueSolid: 'text-white bg-kb-light-blue',
        magentaSolid: 'bg-[#F0F0F1] text-[#84888D]'
    };

    return (
        <button
            className={`py-3 justify-center min-w-[80px] flex items-center text-sm rounded-xl px-5 font-semibold leading-none
             ${config[props.type] + ' ' + props.classes}`}
            {...props}
        >
            {props.children}
        </button>
    );
};

export default Button;
