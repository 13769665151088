
  import axios from "axios";
  import {HOSTNAME} from './APIConstant';
  import Cookies from 'universal-cookie';
  const cookies = new Cookies();

export const offer_list = (dtl) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.post(
        HOSTNAME + `/api/v1/offer/getall`,
        dtl,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
        return error.message;
    }
  };
  

  export const offer_add = (catdtl) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "multipart/form-data",'token':cookies.get('token') } };
  
      if (catdtl.Id !== '') {
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/offer/update`,
          catdtl,
          config
        );
          console.log(data);
        return (data);
      }
      else{
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/offer/add`,
          catdtl,
          config
        );
          console.log(data);
        return (data);
      }
    } catch (error) {
      return error.message;
    }
  };

  export const getofferdtlbyid = (id) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      console.log(id);
      
      const { data } = await axios.post(
        HOSTNAME + `/api/v1/offer/offerdtlbyid`,
        id,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      console.log(error);
      return error.message;
    }
  };

  export const offer_del = (catdtl) => async (dispatch) => {
    try {
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
     
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/offer/delete`,
          catdtl,
          config
        );
          console.log(data);
        return (data);
    } catch (error) {
      return error.message;
    }
  };

  export const getautodtl = () => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      
      const { data } = await axios.get(
        HOSTNAME + `/api/v1/offer/autodata`,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      return error.message;
    }
  };



  export const upload_excel_offer = (catdtl) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "multipart/form-data",'token':cookies.get('token') } };
  
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/offer/upload_excel_offer`,
          catdtl,
          config
        );
          console.log(data);
        return (data);
      
    } catch (error) {
      return error.message;
    }
  };
