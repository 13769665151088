import React from 'react';
import CategoryTableActions from './CategoryTableActions';
import Table from '../utils/Table';


const CategoryTable = ( {data} ) => {
    
  console.log("data",data);
    const COLUMNS = [
        {
            Header: '☑️',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <input type="checkbox" />
                </div>
            )
        },
        {
            Header: 'Title',
            accessor: 'name'
        },
        // {
        //     Header: 'Title',
        //     Cell: (tableProps) => (
        //         <div className="flex items-center">
        //             <img
        //                 src={tableProps.row.original.image}
        //                 className="w-16 rounded-lg mr-4"
        //                 alt={tableProps.row.original.name}
        //             />
        //         </div>
        //     )
        // },

        {
            Header: 'Is Active',
            Cell: (tableProps) => (
                <div className="flex items-center">
                   <span>{tableProps.row.original.is_active.toString()}</span>
                </div>
            )
        },

        { Header: 'Actions', Cell: (tableProps) => 
        tableProps.row.original.is_active ? 
        <CategoryTableActions data={tableProps.row.original._id}/>
        : '' }
    ];

    

   
    return (
        <div>
            <Table columnData={COLUMNS} rowData={data} />
        </div>
    );
};


export default CategoryTable;
