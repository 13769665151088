import {
   BANK_ADD,BANK_LIST,BANK_UPDATE,BANK_REQUEST,
    CLEAR_ERRORS
} from "../constants/bankConstants"

export const bankReducer = (state = { bank: {} }, action) => {
    switch (action.type) {
        case BANK_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
            };
        case BANK_LIST:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                bank: action,
            };

            case BANK_ADD:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                bank: action.payload
            };


        case BANK_UPDATE:
            return {
                loading: false,
                user: null,
                isAuthenticated: false,
            };
       
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };


        default:
            return state;
    }
};