import React from 'react';
import { InputFile, InputText } from '../utils/Input';
import BodyTextEditor from '../utils/TextEditor';

const OfferForm = ({ values, onChange }) => {
    return (
        <form className="grid grid-cols-2 gap-4 bg-white py-5 px-4 rounded-lg">
            <div className="col-span-2">
                <h2 className="font-bold text-base text-[##11142D] mb-3">Offer Details</h2>
                <hr className="mb-2" />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="cardName"
                    label="Card Name"
                    value={values.cardName}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputFile
                    name="cardImage"
                    label="Card Image"
                    value={values.cardImage}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="cardType"
                    label="Card Type"
                    value={values.cardType}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="annualRate"
                    label="Annual Rate"
                    value={values.annualRate}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="balanceTransfer"
                    label="Balance Transfer"
                    value={values.balanceTransfer}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="cashBack"
                    label="CashBack Name"
                    value={values.cashBack}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="minSalary"
                    label="Minimum Salary"
                    value={values.minSalary}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="fxRate"
                    label="FX Rate Name"
                    value={values.fxRate}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="monthlyFees"
                    label="Monthly Fees"
                    value={values.monthlyFees}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2">
                <p className="text-[#8C969F] text-sm mb-4">Description</p>
                <BodyTextEditor name="cardDesc" data={values.cardDesc} onChange={onChange} />
            </div>
        </form>
    );
};

export default OfferForm;
