import React from 'react';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';

import Logo from '../assets/logo-dark.png';
import Data from '../sitedata/Sidebar';

const NavLink = ({ title, icon, to }) => {
    let resolved = useResolvedPath(to);

    let match = useMatch({ path: resolved.pathname, end: false });
    return (
        <Link to={to}>
            <li
                className={`px-5 py-4 flex items-center rounded-xl ${
                    match ? 'bg-[#557095] text-white' : 'text-[#808191] bg-white'
                }`}
            >
                <span className="sidebar-icon mr-4 flex items-center">
                    {match ? icon.active : icon.normal}
                </span>
                <span>{title}</span>
            </li>
        </Link>
    );
};

const Sidebar = () => {
    return (
        <div className="w-64 border h-screen flex flex-col justify-between sticky top-0">
            <div className="py-12 px-5">
                <img src={Logo} alt="logo" className="w-40 object-contain" />
            </div>
            <div className="px-5">
                <p className="text-xs px-5 text-[#B2B3BD] mb-4">Admin Tools</p>

                <ul className="text-[14px] ">
                    {Data.map((item, index) => (
                        <NavLink key={index} title={item.title} icon={item.icon} to={item.to} />
                    ))}
                </ul>
            </div>
            <div className="px-5 py-6 flex">
                <div className="mr-4">
                    <ion-icon name="person-circle" size="large" className="ion-avatar" />
                </div>
                <div className="flex flex-col mr-4">
                    <h2 className="text-[14px] font-semibold">Person Name</h2>
                    <h2 className="text-[10px] text-[#808191] font-semibold">Free Account</h2>
                </div>
                <div className="flex justify-center items-center">
                    <ion-icon name="chevron-down"></ion-icon>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
