import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { ChervonDown } from '../../assets';
import OutletsTable from '../../components/OutletByUser/OutletsTable';
import {outlet_list} from '../../action/outletbyuserAction';
import {useDispatch} from "react-redux";

const OutletByUser = () => {
    const [out_list, setOutlet] = useState([]);
    
    if (out_list.length === 0) {
    OutletTabledata().then(data => {
        console.log(data.data);
        setOutlet(data.data);
    });
    console.log("out_list",out_list);
    }
    return (
        <div>
            <div className="w-full flex justify-between mb-4">
                <div className="flex justify-start items-center gap-4 text-sm font-semibold text-kb-dark-gray ">
                    <div className="bg-kb-light-gray px-6 py-3 flex justify-between items-center w-48">
                        <span className="">Filter 1</span>
                        <ChervonDown />
                    </div>
                </div>
            </div>
        <OutletsTable
                data={out_list}
            />
    </div>
    );
};

const OutletTabledata = async() =>{
   
    const dispatch = useDispatch();

    var outlet = await dispatch(outlet_list());
    return outlet;
}

export default OutletByUser;
