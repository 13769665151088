import React, { useState,useEffect} from 'react';
import { BlueSphere } from '../../assets';
import {useDispatch} from "react-redux";
import { get_bank_count } from '../../action/bankAction';

const BankHeader = () => {
    const dispatch = useDispatch();
   
    const [total_bank, setBankCount] = useState('');
    
    
    useEffect(() => {
        //if (total_bank === '') {
            Bankcount();
        //}
    }, []);
        const Bankcount = async() => {
            var bank =  await dispatch(get_bank_count());
            setBankCount(bank);
    }
  
    return (
        <div className="w-full pt-11 pb-8 px-10 flex text-3xl gap-10">
            <div className="flex items-center">
                <img src={BlueSphere} alt="none" className="w-14 h-14 mr-4" />
                <span className=" font-bold">{total_bank}&nbsp; </span>
                <span className="font-light">Total Banks</span>
            </div>
            {/* <div className="flex items-center">
                <img src={BlueSphere} alt="none" className="w-14 h-14 mr-4" />
                <span className=" font-bold">350&nbsp; </span>
                <span className="font-light">Total Banks</span>
            </div> */}
        </div>
    );
};

const BankTotalCount = async() =>{
    const dispatch = useDispatch();
   var bank = await dispatch(get_bank_count());
    
    return bank;
}

export default BankHeader;
