import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LeftArrow, UploadCloud } from '../../assets';
import CategoryForm from '../../components/Category/CategoryForm';
import OutletForm from '../../components/Outlets/OutletForm';
import Button from '../../components/utils/Button';
import {useDispatch,useSelector} from "react-redux";
import { GetOutletDtlById, Outlet_Add } from '../../action/outletAction';

const AddOutlet = () => {
    const Id = window.location.href.split('/')[5];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        outletname: '',
        logo: '',
        description: '',
        country: '',
        location: '',
        outlet_latitude: '',
        outlet_longitude: '',
        Id:''
    });

    const dtlbyid = async () => {
        const data={
            Id:Id
        }
        var dtl = await dispatch(GetOutletDtlById(data));
        console.log(dtl);
    if (dtl.success) {
            setFormData((prevState) => {
                console.log(prevState);
                prevState.outletname = dtl.data[0].outletname;
                prevState.description = dtl.data[0].description;
                prevState.logo = ({'name':dtl.data[0].logo});
                prevState.country = dtl.data[0].country;
                prevState.location = dtl.data[0].location;
                prevState.outlet_latitude = dtl.data[0].outlet_latitude;
                prevState.outlet_longitude = dtl.data[0].outlet_longitude;
                prevState.Id = Id;
                return { ...prevState };
            });
    }
    }

    useEffect(() => {
        if (Id !== undefined) {
            
            dtlbyid();
        }
    }, [Id]);

    const handleChange = (event) => {
        if (event.target.files !== null) {
            const { name, files } = event.target;
            setFormData((prevState) => {
                return { ...prevState, [name]: files[0] };
            });
        }
        else{
            const { name, value } = event.target;
            setFormData((prevState) => {
                return { ...prevState, [name]: value };
            });
        }

    };

    // const uploadImage = async (e) => {
    //     const file = e.target.files[0];
    //     const base64 = await getBase64(file);
    //     setFormData({ ...formData, logoBase64: base64, logoFileName: file.name });
    // };

    const handleSubmit = async(event) => {
        event.preventDefault();
        console.log(formData);

        var dtl = await dispatch(Outlet_Add(formData));
        if (dtl.success) {
            return navigate('/outlets');
        }
    };

    return (
        <div className="">
            <div className="bg-white pt-11 pb-8 px-10 flex text-3xl justify-center ">
                <div className="flex items-center flex-1">
                    <span className=" font-bold">New Outlet</span>
                </div>
                <div className="flex gap-3 text-sm">
                    <Link to="/banks/ID/upload-card">
                        <Button type="blueBorder">
                            <img src={UploadCloud} alt="icon" />
                            <span className="ml-3">Bulk Outlet Upload</span>
                        </Button>
                    </Link>
                </div>
            </div>
            <hr />
            <div className="bg-white sticky top-0 z-50 py-5 pl-10 pr-16 flex justify-between items-center shadow-lg">
                <div className="flex items-center">
                    <span className="mr-5" onClick={() => navigate(-1)}>
                        <img src={LeftArrow} alt="leftarrow" className="w-12 " />
                    </span>
                    <span className="font-bold text-xl">Add New Outlet </span>
                </div>
                <div className="flex gap-3 text-sm">
                    <Button type="redBorder">
                        <span>Cancel</span>
                    </Button>
                    <Button type="blueSolid" onClick={handleSubmit}>
                    {Id !== undefined  ? 'Update' : 'Create'} 
                    </Button>
                </div>
            </div>
            <div className="w-full h-full bg-kb-light-gray bg-opacity-30 py-4 px-11">
                {/* <OutletForm values={formData} onChange={handleChange} handleImage={uploadImage} /> */}
                <OutletForm values={formData} onChange={handleChange}  />
            </div>
        </div>
    );
};

export default AddOutlet;
