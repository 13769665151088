import React from 'react';
import { BlueSphere } from '../../assets';

const Header = ({ data = [{ title: '', value: 0 }] }) => {
    return (
        <>
            <div className="w-full pt-11 pb-8 px-10 flex text-3xl gap-10">
                {data.map((item) => (
                    <div className="flex items-center">
                        <img src={BlueSphere} alt="none" className="w-14 h-14 mr-4" />
                        <span className=" font-bold">{item.value.toLocaleString()}&nbsp; </span>
                        <span className="font-light">Total {item.title}</span>
                    </div>
                ))}
            </div>
            <hr className="" />
        </>
    );
};

export default Header;
