import React, {useState,useEffect}  from 'react';
import { BlueSphere, LeftArrow } from '../../assets';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/utils/Button';
import UserCardsTable from '../../components/Users/UserCardsTable';
import UserCardApplicationTable from '../../components/Users/UserCardApplicationTable';
import UserVoucher from '../../components/Users/UserVoucher';
import {user_dtl_byid} from '../../action/userAction';
import {useDispatch,useSelector} from "react-redux";

const User = ({ actions }) => {
    const navigate = useNavigate(-1);
    const Id = window.location.href.split('/')[4];
    const dispatch = useDispatch();

    const [useralldtl, setUser] = useState([]);
    
    
const UserAlldata = async(useid) =>{
    var user = await dispatch(user_dtl_byid(useid));
    console.log(user);
    setUser(user);
    return user;
}
   
    useEffect(() => {
        if (useralldtl.length === 0) {
            var useid = {
                Id:Id
            }
        UserAlldata(useid)
        console.log("user_list",useralldtl.length);
        }
    }, []);


    return (
        <div>
            <div className="bg-white flex justify-between items-center py-5 pl-10 pr-16 sticky top-0 shadow-md z-10">
                <div className="flex items-center">
                    <span className="mr-5" onClick={() => navigate(-1)}>
                        <img src={LeftArrow} alt="leftarrow" className="w-12 " />
                    </span>
                    <span className="mr-4">
                        <img src={BlueSphere} alt="bank_logo" className="w-12 rounded-full" />
                    </span>
                    <span className="font-bold text-base text-[##11142D]">Chiranjivi</span>
                </div>
                <div className="flex gap-3 text-sm">
                    <Button type="magentaSolid">Block</Button>
                    <Button type="redBorder">Delete </Button>
                </div>
            </div>
            <div className="shadow-inner-xl bg-kb-light-gray bg-opacity-30 px-10 py-5 flex flex-col gap-4">
                <div className="flex bg-white py-7 px-9 gap-8 rounded-lg">
                    <div className="w-1/2">
                        <h2 className="font-semibold text-base text-[11142D] ">User Details</h2>
                        <hr className="my-4" />

                        {[
                            { title: 'Name', value: useralldtl.length === 0 ?  '-' : useralldtl.data.users[0].name },
                            { title: 'Gender', value: useralldtl.length === 0 ?  '-' : useralldtl.data.users[0].gender  },
                            { title: 'Email Address', value: useralldtl.length === 0 ?  '-' : useralldtl.data.users[0].email  },
                            { title: 'Phone Number', value: useralldtl.length === 0 ?  '-' : useralldtl.data.users[0].country_code +' ' +useralldtl.data.users[0].phone  },
                            { title: 'Status', value: useralldtl.length === 0 ?  '-' : useralldtl.data.users[0].is_active === true ? 'Active' : 'Inactive'  }
                        ].map((item) => (
                            <div className="flex pb-4 text-sm">
                                <span className="w-48 mr-2 text-[#B2B3BD] ">{item.title}</span>
                                <span className="mr-12">:</span>
                                <span className="text-[#11142D] font-medium">{item.value}</span>
                            </div>
                        ))}
                    </div>
                    <div className="w-1/2">
                        <h2 className="font-semibold text-base text-[11142D] ">User Interest</h2>
                        <hr className="my-4" />
                        <div className="flex flex-wrap gap-2">
                            { useralldtl.length === 0 ?  '' : useralldtl.data.userCategories.length === 0 ? <b>{'No Record Found.'}</b> :  useralldtl.data.userCategories[0].category_id.map((item) => (
                                <span
                                    key={item._id}
                                    style={{display: 'flex'}}
                                    className="px-4 py-1 text-sm rounded-lg bg-blue-100 font-semibold text-blue-500"
                                >
                                    <img src={item.image} style={{width: '20px',height: '20px'}} className=""></img>
                                    {item.name}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>

                <UserCardsTable
                    data={useralldtl.length === 0 ?  '' : useralldtl.data.userCard}
                />

                <UserCardApplicationTable
                    data={[
                        {
                            card_name: 'Black',
                            phone: 'none',
                            bankname: 'sbi'
                        }
                    ]}
                />

                <UserVoucher
                    data={[
                        {
                            card_name: 'Black',
                            phone: 'none',
                            bankname: 'sbi'
                        }
                    ]}
                />
            </div>
        </div>
    );
};



export default User;
