import React from 'react';
import { Link, useParams } from 'react-router-dom';
import OutletOffersTable from '../../components/Outlets/OutletOffersTable';
import Button from '../../components/utils/Button';
import OutletLayout from '../../layouts/OutletLayout';

const OutletOffers = () => {
    const { id } = useParams();

    return (
        <OutletLayout
            actions={
                <div className="flex gap-3 text-sm">
                    <Link to={`/outlets/${id}/offers/add`}>
                        <Button type="blueBorder">Upload Offers</Button>
                    </Link>
                </div>
            }
        >
            <div className="px-10 py-5">
                <OutletOffersTable data={[{ title: 'Food', is_active: false }]} />
            </div>
        </OutletLayout>
    );
};

export default OutletOffers;
