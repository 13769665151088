import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BankIcon, BankLogo, DownloadCloud, VerticalDotsIcon } from '../../assets';
import { bank_delete, get_bank_list } from '../../action/bankAction';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ActionCard from './ActionCard';
import { BANK_ADD } from '../../constants/bankConstants';
import Table from '../../components/utils/Table';
import { FilterSearch } from '../utils/Input';



const BankTable = () => {


    const [bank_list, setBank] = useState([]);
    const [Status, setStatus] = useState(-1);
    const [bank_name, setBankName] = useState('');

    const dispatch = useDispatch();


    useEffect(() => {
        console.log("call3232332", Status);
        var payload = {
            'Status': Status,
            'BankName': bank_name
        }
        console.log("call3232332", Status);
        var bank = dispatch(get_bank_list(payload));
        bank.then(function (val) {
            console.log(val);
            setBank(val);
        })
    }, [Status, bank_name])

    const COLUMNS = [
        {
            Header: 'Bank Name',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        src={tableProps.row.original.bank_logo}
                        className="w-16 rounded-lg mr-4"
                        alt={' '}
                    />
                    <Link to={"/banks/" + tableProps.row.original._id} state={{ "bank_logo": tableProps.row.original.bank_logo, "bank_name": tableProps.row.original.bank_name }}>
                        <span>{tableProps.row.original.bank_name}</span>
                    </Link>
                </div>
            )
        },
        {
            Header: 'Total Cards',
            Cell: (tableProps) => tableProps.row.original.total_card.length + ' Cards'
        },
        {
            Header: 'In App Card Holder',
            Cell: (tableProps) => tableProps.row.original.usercard.length + ' Card Holders'
        },
        {
            Header: 'Available Offers',
            Cell: (tableProps) => tableProps.row.original.total_offer.length + ' Offers'
        },
        {
            Header: 'Card Applications',
            Cell: (tableProps) => (
                <span className="flex py-4 text-kb-light-blue">
                    <span>{tableProps.row.original.total_application.length} Application</span>
                    <img src={DownloadCloud} alt="img" className="w-4 object-contain ml-1" />
                </span>
            )
        },
        {
            Header: 'Status',
            Cell: (tableProps) => tableProps.row.original.is_active.toString()
        },
        {
            Header: 'Actions', Cell: (tableProps) => tableProps.row.original.is_active ?
                <ActionCard data={{ "_id": tableProps.row.original._id, "bank_logo": tableProps.row.original.bank_logo, "bank_name": tableProps.row.original.bank_name }} /> : ''
        }
    ];



    return (
        <>
            <div className="w-full flex justify-between pb-5">
                <div className="flex justify-start items-center gap-4 text-sm font-semibold text-kb-dark-gray ">
                    <div className="">
                        <span>
                            Bank Name
                        </span>
                        <div className={`flex flex-col text-sm col-span-2 lg:col-span-1`}>
                            <input
                                className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
                                placeholder='Filter By Bank Name'
                                type="text"
                                onChange={(e) => setBankName(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* <FilterSearch 
                title = "Status"
                /> */}
                    <div className="grid">
                        <span>
                            Status
                        </span>
                        <select
                            className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option className="py-2" value={-1} > All </option>
                            <option className="py-2" value={1} >True</option>
                            <option className="py-2" value={0} >False</option>
                        </select>
                    </div>
                </div>
                <div className="flex">
                    <Link to="/banks/bankadd">
                        <button className="rounded-xl bg-kb-light-blue py-3 px-7 text-white font-bold relative">
                            <span className="w-full h-full bg-[#E3E6EC] absolute top-0 left-0 blur-md mix-blend-multiply -z-10 border"></span>
                            + Add bank
                        </button>
                    </Link>
                </div>
            </div>
            <Table columnData={COLUMNS} rowData={bank_list.data !== undefined && bank_list.data.length > 0 ? bank_list.data : []} />
        </>
    );
};



export default BankTable;