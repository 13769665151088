import axios from "axios";
import { HOSTNAME } from './APIConstant';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const app_list = (payload) => async (dispatch) => {
  try {

    const config = { headers: { "Content-Type": "application/json", 'token': cookies.get('token') } };

    const { data } = await axios.post(
      HOSTNAME + `/api/v1/app/getall`,
      payload,
      config
    );
    console.log(data);
    return (data);
  } catch (error) {
    dispatch({ payload: error.response.data.message });
  }
};

export const application_delete = (id) => async (dispatch) => {
  try {

    const config = { headers: { "Content-Type": "application/json", 'token': cookies.get('token') } };

    console.log(id);
    const { data } = await axios.post(
      HOSTNAME + `/api/v1/app/delete_app`,
      id,
      config
    );
    console.log(data);
    return (data);

  } catch (error) {
    dispatch({ payload: error.response.data.message });
  }
};