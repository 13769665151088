import {
    CATEGORY_ADD,
    CATEGORY_LIST,
    CLEAR_ERRORS,
    CATEGORY_REQUEST
  } from "../constants/categoryConstants";
  import axios from "axios";
  import {HOSTNAME} from './APIConstant';
  import Cookies from 'universal-cookie';
  const cookies = new Cookies();

export const cat_list = () => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.get(
        HOSTNAME + `/api/v1/category/getall`,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      return (error.response.data.message);
    }
  };

  export const cat_chart = () => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.get(
        HOSTNAME + `/api/v1/category/getchartdtl`,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      return (error.response.data.message);
    }
  };
  

  export const cat_add = (catdtl) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "multipart/form-data",'token':cookies.get('token') } };
  
      if (catdtl.Id !== '') {
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/category/update`,
          catdtl,
          config
        );
          console.log(data);
        return (data);
      }
      else{
        const { data } = await axios.post(
          `/api/v1/category/save`,
          catdtl,
          config
        );
          console.log(data);
        return (data);
      }
    } catch (error) {
      return error.message;
    }
  };

  export const getcatdtlbyid = (id) => async (dispatch) => {
    try {
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      console.log(id);
      
      const { data } = await axios.post(
        HOSTNAME + `/api/v1/category/catdtlbyid`,
        id,
        config
      );
        console.log(data);
      return (data);
    } catch (error) {
      return error.message;
    }
  };

  export const cat_del = (catdtl) => async (dispatch) => {
    try {
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
     
        const { data } = await axios.post(
          HOSTNAME + `/api/v1/category/delete`,
          catdtl,
          config
        );
          console.log(data);
        return (data);
    } catch (error) {
      return error.message;
    }
  };

  // Clearing Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };


  export const getautocat_list = () => async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_REQUEST });
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data } = await axios.get(
        HOSTNAME + `/api/v1/category/getallautocategory`,
        config
      );
        console.log(data);
      dispatch({ type: CATEGORY_LIST, payload: data });
      return (data);
    } catch (error) {
      return (error.response.data.message);
    }
  };