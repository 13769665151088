import React, { useState,useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { get_outlet_count } from '../action/outletAction';
import { BlueSphere } from '../assets';
import Header from '../components/utils/Header';
import {useDispatch} from "react-redux";

const OutletsLayout = ({ children }) => {
    const dispatch = useDispatch();
    
    const [totalOutlets, setTotalOutlets] = useState(0);

    useEffect(() => {
        Outletcount();
}, []);
    const Outletcount = async() => {
        var outlet =  await dispatch(get_outlet_count());
        setTotalOutlets(outlet);
}


    return (
        <>
           <Header data={[{ title: 'Outlets', value: totalOutlets }]} />
            <div className="py-5 px-10">
                <Outlet />
            </div>
        </>
    );
};

export default OutletsLayout;
