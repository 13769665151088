import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { ChervonDown } from '../../assets';
import OutletsTable from '../../components/Outlets/OutletsTable';
import {outlet_list} from '../../action/outletAction';
import {useDispatch} from "react-redux";

const Outlets = () => {
    const [out_list, setOutlet] = useState([]);
    
    if (out_list.length === 0) {
    OutletTabledata().then(data => {
        console.log(data.data);
        setOutlet(data.data);
    });
    console.log("out_list",out_list);
    }

    return (
        <div>
            <div className="w-full flex justify-between mb-4">
                <div className="flex justify-start items-center gap-4 text-sm font-semibold text-kb-dark-gray ">
                    <div className="bg-kb-light-gray px-6 py-3 flex justify-between items-center w-48">
                        <span className="">Filter 1</span>
                        <ChervonDown />
                    </div>
                    <div className="bg-kb-light-gray px-6 py-3 flex justify-between items-center w-48">
                        <span className="">Filter 1</span>
                        <ChervonDown />
                    </div>
                    <div className="bg-kb-light-gray px-6 py-3 flex justify-between items-center w-48">
                        <span className="">Filter 1</span>
                        <ChervonDown />
                    </div>
                </div>
                <div className="flex">
                    <Link to="/outlets/add">
                        <button className="rounded-xl bg-kb-light-blue py-3 px-7 text-white font-bold relative">
                            <span className="w-full h-full bg-[#E3E6EC] absolute top-0 left-0 blur-md mix-blend-multiply -z-10 border"></span>
                            + Add Outlet
                        </button>
                    </Link>
                </div>
            </div>
            <OutletsTable
                data={out_list}
            />
        </div>
    );
};

const OutletTabledata = async() =>{
   
    const dispatch = useDispatch();

    var outlet = await dispatch(outlet_list());
    return outlet;
}



export default Outlets;
