import React,{useState,useEffect} from 'react';
import CardDescription from '../../components/Banks/CardDescription';
import CardDetails from '../../components/Banks/CardDetails';
import CardHeader from '../../components/Banks/CardHeader';
import CardOffers from '../../components/Banks/CardOffers';
import BankLayout from '../../layouts/BankLayout';
import { Link, useLocation } from 'react-router-dom';
import {useDispatch} from "react-redux";
import { getcarddtlall } from '../../action/bancardAction';

const Card = () => {
    var cardid = window.location.href.split('/');
    var Id = cardid[cardid.length - 1];
    const loaction = useLocation()
    const dispatch = useDispatch();

    const [alldata, setData] = useState([])
    const [Bank_all_dtl, setBankAllDtl] = useState(loaction.state)
    
    const carddtl = async () => {
       let cardid = {
        id:Id
       }
        var dtl = await dispatch(getcarddtlall(cardid));
        setData(dtl.data)
        setBankAllDtl(dtl.data.carddtl[0].bank_id)
        console.log(dtl);
    }

    useEffect(() => {
        carddtl();
    }, [Id]);

    return (
        <BankLayout data={Bank_all_dtl}>
            <div className="py-5 pl-10 pr-16 shadow-inner-xl bg-[#E4E4E440] flex flex-col gap-3">
                <CardHeader data={alldata}/>
                <CardOffers data={alldata}/>
                <CardDetails data={alldata}/>
                <CardDescription data={alldata}/>
            </div>
        </BankLayout>
    );
};

export default Card;
