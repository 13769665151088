import React from 'react';
import Table from '../utils/Table';

const UserVoucher = ({ data }) => {
    const COLUMNS = [
        {
            Header: 'Name of Outlet',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        // src={tableProps.row.original.card_image}
                        className="w-11 rounded-full mr-4"
                        alt={tableProps.row.original.card_name}
                    />
                    <span>{tableProps.row.original.card_name}</span>
                </div>
            )
        },

        {
            Header: 'Voucher Type',
            accessor: 'bankname'
        },
        {
            Header: 'Value of Voucher',
            accessor: 'phone'
        },
        {
            Header: 'Voucher Code',
            accessor: '1phone'
        },
        {
            Header: 'Voucher category',
            accessor: '2phone'
        },
        {
            Header: 'Expiry Date',
            accessor: '3phone'
        }
    ];

    return (
        <div className="bg-white py-5 px-6">
            <h2 className="font-semibold text-base text-[11142D] ">Vouchers</h2>
            <hr className="my-4" />

            <Table columnData={COLUMNS} rowData={data} />
        </div>
    );
};

export default UserVoucher;
