import axios from "axios";
import { HOSTNAME } from './APIConstant';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const get_overview_dtl = () => async (dispatch) => {
  try {
    var datares = { 'token': cookies.get('token') }
    console.log(datares);
    const config = { headers: { "Content-Type": "application/json", 'token': cookies.get('token') } };


    const { data } = await axios.get(
      HOSTNAME + `/api/v1/overview/alldata`,
      config
    );
    console.log(data);
    return (data);
  } catch (error) {
    return error.response.data.message;
  }
};
