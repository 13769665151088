import React , { useState }from 'react';
import { InputFile, InputText } from '../utils/Input';
import BodyTextEditor from '../utils/TextEditor';

const CategoryForm = ({ values, onChange }) => {
    // const [Desc, setDescription] = useState(values.other_description);
    // const  SetDesc = (event) => {
    //     setDescription(event);
    //     values.other_description = event;
    // }

   



    return (
        <div>
            <form className="grid grid-cols-2 gap-4">
                <InputText
                    name="name"
                    label="Name of category"
                    value={values.name}
                    onChange={onChange}
                />
                <InputFile
                    name="image"
                    label="Upload Logo"
                    //value={values.image}
                    val={values.image.name}
                    val1={values.image}
                    onChange={onChange}
                />
                
                <div className="col-span-2">
                    <BodyTextEditor
                        // name="description"
                        label="Description"
                        value={values.other_description}
                        data={values.other_description}
                        onChange={(item) => values.other_description = item}
                    />
                </div>
            </form>
        </div>
    );
};

export default CategoryForm;
