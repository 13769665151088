import {
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    CLEAR_ERRORS,
    LOGOUT_FAIL,
    LOGOUT_SUCCESS
  } from "../constants/loginConstants";
  import axios from "axios";
  import {HOSTNAME} from './APIConstant';
  import Cookies from 'universal-cookie';
  const cookies = new Cookies();
  

// Login
export const login = (email, password) => async (dispatch) => {
    try {
      console.log("call=>");
      dispatch({ type: LOGIN_REQUEST });
  
      const config = { headers: { "Content-Type": "application/json",'token':cookies.get('token') } };
  
      const { data}  = await axios.post(
        HOSTNAME + `/api/v1/login`,
        { email, password },
        config
      );
        console.log(data);
        data.success === true ? cookies.set('token',data.token) : cookies.remove('token');
      dispatch({ type: LOGIN_SUCCESS, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
    }
  };

  // Clearing Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };
  
  // Logout User
export const logout = () => async (dispatch) => {
    try {
      await axios.get(HOSTNAME + `/api/v1/logout`);
  
      dispatch({ type: LOGOUT_SUCCESS });
    } catch (error) {
      dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
    }
  };