import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LeftArrow, UploadCloud } from '../../assets';
import OutletOfferForm from '../../components/Outlets/OutletOfferForm';
import Button from '../../components/utils/Button';

const AddOutletOffer = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        logo: '',
        description: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => {
            return { ...prevState, [name]: value };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
    };

    return (
        <div>
            <div className="w-full pt-11 pb-8 px-10 flex justify-between text-3xl gap-10">
                <div className="flex items-center">
                    <span className=" font-bold">New Outlet</span>
                </div>
                <div className="flex gap-3 text-sm">
                    <Link to="/banks/ID/upload-card">
                        <Button type="blueBorder">
                            <img src={UploadCloud} alt="icon" />
                            <span className="ml-3">Bulk Offers Upload</span>
                        </Button>
                    </Link>
                </div>
            </div>
            <hr />
            <div className="bg-white sticky top-0 z-50 py-5 pl-10 pr-16 flex justify-between items-center shadow-lg">
                <div className="flex items-center">
                    <span className="mr-5" onClick={() => navigate(-1)}>
                        <img src={LeftArrow} alt="leftarrow" className="w-12 " />
                    </span>
                    <span className="font-bold text-xl">Add New Outlet </span>
                </div>
                <div className="flex gap-3 text-sm">
                    <Button type="redBorder">
                        <span>Cancel</span>
                    </Button>
                    <Button type="blueSolid" onClick={handleSubmit}>
                        Create
                    </Button>
                </div>
            </div>
            <div className="w-full h-full bg-kb-light-gray bg-opacity-30 py-4 px-11">
                <OutletOfferForm values={formData} onChange={handleChange} />
            </div>
        </div>
    );
};

export default AddOutletOffer;
