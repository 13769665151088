import React from 'react';

function PercentageIcon({ color = '#fff' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
            <g id="_Icon" data-name="🍎Icon" transform="translate(0.75 0.75)">
                <g id="percent" transform="translate(-4 -4)" opacity="1">
                    <line
                        id="Line_40"
                        data-name="Line 40"
                        x1="14"
                        y2="14"
                        transform="translate(5 5)"
                        fill="none"
                        stroke={color}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <circle
                        id="Ellipse_47"
                        data-name="Ellipse 47"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="translate(4 4)"
                        fill="none"
                        stroke={color}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <circle
                        id="Ellipse_48"
                        data-name="Ellipse 48"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="translate(15 15)"
                        fill="none"
                        stroke={color}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                </g>
            </g>
        </svg>
    );
}

export default PercentageIcon;
