import React from 'react';
import Table from '../utils/Table';

const UserCardApplicationTable = ({ data }) => {
    const COLUMNS = [
        {
            Header: 'Card Name',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        // src={tableProps.row.original.card_image}
                        className="w-11 rounded-full mr-4"
                        alt={tableProps.row.original.card_name}
                    />
                    <span>{tableProps.row.original.card_name}</span>
                </div>
            )
        },

        {
            Header: 'Bank name',
            accessor: 'bankname'
        },
        {
            Header: 'Card Expiry Date',
            accessor: 'phone'
        },
        {
            Header: 'Payment due date',
            accessor: '1phone'
        },
        {
            Header: 'Available Offers',
            accessor: '2phone'
        }
    ];
    return (
        <div className="bg-white py-5 px-6">
            <h2 className="font-semibold text-base text-[11142D] ">Payment Card Application</h2>
            <hr className="my-4" />
            <Table columnData={COLUMNS} rowData={data} />
        </div>
    );
};

export default UserCardApplicationTable;
