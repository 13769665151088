import React, { useState } from 'react';
import Table from '../utils/Table';
import UsersTableActions from './UsersTableActions';

const UserCardOffersTable = ({ data, onBack }) => {
    const [activeRow, setActiveRow] = useState(0);

    const updateActiveRow = (id) => {
        setActiveRow(activeRow !== id ? id : -1);
    };

    const OFFERS_COLUMNS = [
        {
            Header: 'Brand/Outlet',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        // src={tableProps.row.original.card_image}
                        className="w-11 rounded-full mr-4"
                        alt={tableProps.row.original.card_name}
                    />
                    <span>{tableProps.row.original.card_name}</span>
                </div>
            )
        },

        {
            Header: 'OfferonCard',
            accessor: 'bankname'
        },
        {
            Header: 'offerdescription',
            accessor: 'phone'
        },
        {
            Header: 'Offertag',
            accessor: '1phone'
        },
        {
            Header: 'Expdt',
            accessor: '2phone'
        },
        {
            Header: 'Country',
            accessor: '4phone'
        },
        {
            Header: 'Location',
            accessor: '3phone'
        },
        {
            Header: 'Actions',
            Cell: (tableProps) => (
                <UsersTableActions
                    active={tableProps.row.original.id === activeRow}
                    onClick={() => updateActiveRow(tableProps.row.original.id)}
                />
            )
        }
    ];

    return (
        <div className="bg-white py-5 px-6">
            <button className="font-semibold text-base text-[11142D]" onClick={onBack}>
                Back
            </button>
            <hr className="my-4" />
            <Table columnData={OFFERS_COLUMNS} rowData={data} />
        </div>
    );
};

export default UserCardOffersTable;
