import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BankLayout from '../../layouts/BankLayout';
import CardForm from '../../components/Banks/CardForm';
import Button from '../../components/utils/Button';
import { useDispatch } from "react-redux";
import { bankcard_add, getautocardtype_list, getcarddtlbyid } from "../../action/bancardAction"

const UploadCard = (data) => {
    const location = useLocation();
    var card_id = window.location.href.split('/')[6];
    const Id = card_id;
    console.log(Id, location.state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [bank_all_dtl, setBankAllDtl] = useState(location.state == null ? Id : location.state)
    const [formData, setFormData] = useState({
        bank_id: Id == undefined ? '' : bank_all_dtl._id,
        card_name: '',
        card_image: '',
        card_type: '',
        annual_charges: '',
        fx_rate: '',
        monthly_fees: '',
        minimum_salary: '',
        balance_transfer: '',
        other_description: '',
        cashback: '',
        Id: '',
        cardTypeList: [],
        categorylist: [],
        category: ''
    });

    const dtlbyid = async () => {
        const data = {
            Id: Id
        }
        var dtl = await dispatch(getcarddtlbyid(data));
        console.log(dtl);
        setBankAllDtl(dtl.data.bank_id);
        console.log(bank_all_dtl, "dasfd");
        if (dtl.success) {
            setFormData((prevState) => {
                console.log(prevState);

                prevState.card_name = dtl.data.card_name;
                prevState.bank_id = dtl.data.bank_id._id;
                prevState.card_image = ({ 'name': dtl.data.card_image });
                prevState.card_type = dtl.data.card_type;
                prevState.annual_charges = dtl.data.annual_charges;
                prevState.fx_rate = dtl.data.fx_rate;
                prevState.monthly_fees = dtl.data.monthly_fees;
                prevState.minimum_salary = dtl.data.minimum_salary;
                prevState.balance_transfer = dtl.data.balance_transfer;
                prevState.other_description = dtl.data.other_description;
                prevState.cashback = dtl.data.cashback;
                prevState.Id = Id;
                prevState.category = dtl.data.category;

                return { ...prevState };
            });
        }
    }

    const cardtypedtl = async () => {

        var dtl = await dispatch(getautocardtype_list());
        console.log(dtl);
        if (dtl.success) {
            setFormData((prevState) => {
                prevState.cardTypeList = dtl.data;
                prevState.categorylist = dtl.categorylist;
                return { ...prevState };
            });
        }
    }

    useEffect(() => {
        cardtypedtl();

        if (Id !== undefined) {

            dtlbyid();
        }

    }, [Id]);

    const handleChange = (event) => {
        console.log(event.target.files);
        if (event.target.files !== null && event.target.files !== undefined) {
            const { name, files } = event.target;
            console.log(URL.createObjectURL(event.target.files[0]));
            console.log(event.target.file);
            setFormData((prevState) => {
                return { ...prevState, [name]: (files[0]) };
            });
        }
        else {
            const { name, value } = event.target;
            setFormData((prevState) => {
                return { ...prevState, [name]: value };
            });
        }

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(formData, bank_all_dtl);

        var dtl = await dispatch(bankcard_add(formData));
        if (dtl.success) {
            return navigate('/banks/' + bank_all_dtl._id);
        }
    };

    return (
        <BankLayout data={bank_all_dtl}
            actions={
                <div className="flex gap-2">
                    <Button type="redBorder">Cancel</Button>
                    <Button type="blueSolid" onClick={handleSubmit}>
                        Upload
                    </Button>
                </div>
            }
        >
            <div className="px-11 py-5 bg-kb-light-gray bg-opacity-30">
                <div className="bg-white py-5 px-4 rounded-lg">
                    <h2 className="font-bold text-base text-[##11142D] mb-3">Card Details</h2>
                    <hr className="mb-4" />
                    <CardForm values={formData} onChange={handleChange} />
                </div>
            </div>
        </BankLayout>
    );
};

export default UploadCard;
