import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChervonDown, UploadCloud } from '../../assets';
import BankOffersTable from '../../components/Offers/BankOffersTable';
import Button from '../../components/utils/Button';
import {  InputSelect } from '../../components/utils/Input';
import {useDispatch,useSelector} from "react-redux";
import { Navigate } from 'react-router-dom';
import { upload_excel_offer,offer_list } from '../../action/offerAction';
import {getautobank_list} from '../../action/bankAction';
import {getautocat_list} from '../../action/categoryAction';

const BanksOffers = () => {
    const dispatch = useDispatch();
    const [databank, setDataBankList] = useState([]);
    const [datacat, setDataCategoryList] = useState([]);
    const [offerlist, setOfferList] = useState([]);
     
     const getdtl = async () => {
       
        var databank_dt = await dispatch(getautobank_list());

        var datacat_dt = await dispatch(getautocat_list());

        var offer_dt = await dispatch(offer_list({offertype:"Bank Offer"}))

        setDataBankList(databank_dt.data);
        setDataCategoryList(datacat_dt.data);
        setOfferList(offer_dt.data)
    }


    useEffect(() => {
        console.log(databank);
        if (databank.length === 0) {
            
            getdtl();
        }
    }, [databank === 0]);

    const hiddenFileInput = React.useRef(null);




    const handleClick = async(event) => {
        console.log(event);
        event.preventDefault();
        hiddenFileInput.current.click();
        console.log("11",hiddenFileInput);
       

    };

   

    const [bank_id, setBank] = useState('');
    const [cat_id, setCategory] = useState('');
    const [file_off, setFileOff] = useState('');
    //const navigate = useNavigate();
    // const datacat= useSelector(
    //     (state) => state.category 
    //   );

    //   const databank= useSelector(
    //     (state) => state.bank 
    //   );

    //   if ( databank.bank.length === undefined) {
    //     console.log("in");
    //     return<Navigate to="/offers" />
    //   }

    const check_file = (event) => {
		setFileOff(event.target.files[0]);
        var offer = {
                        'offer_excel':event.target.files[0],
                        'bank':bank_id,
                        'category':cat_id,
                        'offertype':"Bank Offer"
                    }
                    var dtl = dispatch(upload_excel_offer(offer));
                   console.log(dtl);
                   window.location.reload();
                }

    return (
        <div className="py-8 px-10">
            <div className="w-full flex justify-between items-center mb-10">
                <div className="flex justify-start items-center gap-4 text-sm font-semibold text-kb-dark-gray ">
                    <div className="bg-kb-light-gray px-6 py-3 flex justify-between items-center w-48">
                        <span className="">Filter 1</span>
                        <ChervonDown />
                    </div>
                    <div className="bg-kb-light-gray px-6 py-3 flex justify-between items-center w-48">
                        <span className="">Filter 1</span>
                        <ChervonDown />
                    </div>
                    <div className="bg-kb-light-gray px-6 py-3 flex justify-between items-center w-48">
                        <span className="">Filter 1</span>
                        <ChervonDown />
                    </div>
                </div>
                <div className="flex  gap-3 text-sm">
                    <InputSelect
                        name="bank_id"
                        value={bank_id}
                        onChange={(event) => setBank(event.target.value)}
                        options={databank}
                    />
                    <InputSelect
                        name="cat_id"
                        value={cat_id}
                        onChange={(event) => setCategory(event.target.value)}
                        options={datacat}
                    />
                </div>
                <div className="flex  gap-3 text-sm">
                   
                    <Button type="blueBorder" onClick={(event) => handleClick(event)} >
                        <img src={UploadCloud} alt="upload_logo" className="mr-2" />
                        <span> Bulk Offer Upload</span>
                    </Button>
                    <input  ref={hiddenFileInput} type="file" className="hidden" onChange={check_file}/>
                    <Link to="/offers/add">
                        <Button type="blueSolid">Upload Offer</Button>
                    </Link>
                </div>
            </div>
            <BankOffersTable data={offerlist}/>
        </div>
    );
};

export default BanksOffers;
