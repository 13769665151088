import React from 'react';
import { AndroidLogo, AppleLogo, FaceBookLogo, GoogleLogo, TwitterLogo } from '../../assets';
import Android  from '../../assets/logos/Android.png';
const data = [
    {
        title: 'Apple',
        logo: AppleLogo,
        value: 0,
        color: 'bg-black'
    },
    {
        title: 'Facebook',
        logo: FaceBookLogo,
        value: 0,
        color: 'bg-fb-blue'
    },
    {
        title: 'Twitter',
        logo: TwitterLogo,
        value: 0,
        color: 'bg-twitter-blue'
    },
    {
        title: 'Google',
        logo: GoogleLogo,
        value: 0,
        color: 'bg-google-blue'
    },
    {
        title: 'Android',
        logo: Android,
        value: 0,
        color: 'bg-blue-500'
    }
];

const Platform = ({ value, title, logo, color }) => (
    <div className="flex w-full">
        <div className="flex justify-center items-center rounded-2xl mr-4 h-14 w-14">
            <img src={logo} alt={title} />
        </div>
        <div className="flex flex-col flex-1 py-1">
            <span className="mb-2">{value}</span>
            <div className="w-full bg-gray-200 rounded-full h-2">
                <div className={`${color} h-2 rounded-full w-[45%]`} />
            </div>
        </div>
    </div>
);

const SignUpRatio = (data1) => {
    console.log(data1);
    return (
        <div className="relative w-full z-50 flex justify-center">
            <div className="h-full top-16 bg-[#E3E6EC] mix-blend-multiply rounded-3xl blur-2xl w-full opacity-90 -z-10 absolute" />
            <div className="bg-white rounded-3xl w-full h-full py-9 px-8">
                <h2 className="font-medium text-[18px] mb-7">Sign Up ratio</h2>
                <div className="flex flex-col gap-5">
                    {data.map((item, index) => (
                        <Platform
                            key={index}
                            value={item.title === 'Apple' ? data1.data.ios : item.title === 'Android' ? data1.data.android :  item.value}
                            logo={item.logo}
                            title={item.title}
                            color={item.color}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SignUpRatio;
