import React from 'react';

function UserIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="19.5" viewBox="0 0 17.5 19.5">
            <g id="_Icon" data-name="🍎Icon" transform="translate(0.75 0.75)">
                <g id="user" transform="translate(-4 -3)" opacity="1">
                    <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M20,21V19a4,4,0,0,0-4-4H8a4,4,0,0,0-4,4v2"
                        fill="none"
                        stroke={color}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <circle
                        id="Ellipse_46"
                        data-name="Ellipse 46"
                        cx="4"
                        cy="4"
                        r="4"
                        transform="translate(8 3)"
                        fill="none"
                        stroke={color}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                </g>
            </g>
        </svg>
    );
}

export default UserIcon;
