import {
    CATEGORY_LIST,CATEGORY_REQUEST,
     CLEAR_ERRORS
 } from "../constants/categoryConstants"
 
 export const categoryReducer = (state = { bank: {} }, action) => {
     switch (action.type) {
         case CATEGORY_REQUEST:
             return {
                 loading: true,
                 isAuthenticated: false,
             };
         case CATEGORY_LIST:
             return {
                 ...state,
                 loading: false,
                 category: action.payload.data,
             };
 
        
         case CLEAR_ERRORS:
             return {
                 ...state,
                 error: null,
             };
 
 
         default:
             return state;
     }
 };