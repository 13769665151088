import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Logout = () => {
    const navigate = useNavigate();
    cookies.remove("token");
    window.location.reload();
    return navigate('/');

};

export default Logout;
