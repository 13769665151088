import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DownloadCloud, UploadCloud } from '../../assets';
import Button from '../../components/utils/Button';
import Table from '../../components/utils/Table';
import BankLayout from '../../layouts/BankLayout';
import BANK_JSON from '../../sitedata/bank.json';
import { bankcard_list } from '../../action/bancardAction';
import { useDispatch } from "react-redux";
import { BANK_ADD, BANK_LIST } from '../../constants/bankConstants';
import CardTableActions from '../../components/Banks/CardTableAction';
import OfferTableActions from '../Offers/OfferTableActions';


const COLUMNS = [
    // {
    //     Header: '☑️',
    //     Cell: (tableProps) => (
    //         <div className="flex items-center">
    //             <input type="checkbox" />
    //         </div>
    //     )
    // },
    {
        Header: 'Brand/Outlet',
        Cell: (tableProps) => (
            <div className="flex items-center">
                <img
                    src={tableProps.row.original.outlet_id.logo}
                    className="w-16 rounded-lg mr-4"
                    alt={tableProps.row.original.outlet_id.outletname}
                />
                <span>{tableProps.row.original.outlet_id.outletname}</span>
            </div>
        )
    },

    {
        Header: 'Offeroncard',
        Cell: (tableProps) => (<><span>{tableProps.row.original.discount + '%  '}</span> <span dangerouslySetInnerHTML={{ __html: tableProps.row.original.other_text.length > 100 ? tableProps.row.original.other_text.substr(0, 100) + ' ....' : tableProps.row.original.other_text }}></span></>)
    },
    {
        Header: 'Offertag',
        accessor: 'offer_tag'
    },
    {
        Header: 'Expdt',
        Cell: (tableProps) => (<span>{tableProps.row.original.offer_expiry_date.split('T')[0]}</span>)
    },
    {
        Header: 'Country',
        Cell: (tableProps) => (<span>{tableProps.row.original.country.name}</span>)
    },
    {
        Header: 'Location',
        Cell: (tableProps) => (<span>{tableProps.row.original.location == null ? '' : tableProps.row.original.location.name}</span>)
    },

    {
        Header: 'Actions',
        Cell: (tableProps) => (
            <OfferTableActions
                data={tableProps.row.original._id}
            />)
    }
];

const BankOffers = () => {
    const location = useLocation();
    var bank_all_dtl = location.state;

    const const_url = window.location.href.split('/');
    const Id = const_url[const_url.length - 1]
    const [card_list, setBankCard] = useState([]);

    if (card_list.status == undefined) {

        BankCardTabledata(Id).then(data => {
            console.log(data);
            setBankCard(data);
        });
    }

    return (
        <BankLayout data={bank_all_dtl === null ? card_list.length > 0 ? card_list.data[0].bank_id : card_list.bank_dtl && card_list.bank_dtl[0] : bank_all_dtl}
            actions={
                <div className="flex gap-3 text-sm">
                    <Button type="blueBorder">
                        <img src={UploadCloud} alt="upload_logo" className="mr-2" />
                        Bulk Cards Upload
                    </Button>
                    <Link to={'/banks/' + Id + '/upload-card'} state={bank_all_dtl === null ? card_list.length > 0 ? card_list.data[0].bank_id : card_list.bank_dtl && card_list.bank_dtl[0] : bank_all_dtl}>
                        <Button type="blueSolid" >Upload Card </Button>
                    </Link>
                </div>
            }
        >
            <div className="py-5 pl-10 pr-16 shadow-inner-xl">
                <Table columnData={COLUMNS} rowData={card_list.data !== undefined && card_list.data.length > 0 ? card_list.data : []} />
            </div>
        </BankLayout>
    );
};

const BankCardTabledata = async (Id) => {
    const dispatch = useDispatch();
    var dtl = {
        'bank_id': Id
    }
    var app = await dispatch(bankcard_list(dtl));
    return app;
}


export default BankOffers;
