import React from 'react';
import Button from '../utils/Button';
import Table from '../utils/Table';
import OFFER_DATA from '../../sitedata/offers.json';
import ActionCard from './ActionCard';
import { Link } from 'react-router-dom';

const COLUMNS = [
    // {
    //     Header: '☑️',
    //     Cell: (tableProps) => (
    //         <div className="flex items-center">
    //             <input type="checkbox" />
    //         </div>
    //     )
    // },
    {
        Header: 'Brand/Outlet',
        Cell: (tableProps) => (
            <div className="flex items-center">
                <img
                    src={tableProps.row.original.outlet_id.logo}
                    className="w-16 rounded-lg mr-4"
                    alt={tableProps.row.original.outlet_id.outletname}
                />
                <span>{tableProps.row.original.outlet_id.outletname}</span>
            </div>
        )
    },

    {
        Header: 'Offeroncard',
        Cell: (tableProps) => (<><span>{tableProps.row.original.discount + '%  '}</span> <span  dangerouslySetInnerHTML={{__html: tableProps.row.original.other_text.length > 100 ? tableProps.row.original.other_text.substr(0, 100) + ' ....' :  tableProps.row.original.other_text}}></span></>)
    },
    {
        Header: 'Offertag',
        accessor: 'offer_tag'
    },
    {
        Header: 'Expdt',
        Cell: (tableProps) => (<span>{tableProps.row.original.offer_expiry_date.split('T')[0]}</span>)
    },
    {
        Header: 'Country',
        Cell: (tableProps) => (<span>{tableProps.row.original.country.name}</span>)
    },
    {
        Header: 'Location',
        Cell: (tableProps) => (<span>{tableProps.row.original.location == null ? '' : tableProps.row.original.location.name}</span>)
    },
    
    {
        Header: 'Actions',
        Cell: (tableProps) => <ActionCard />
    }
];

const CardOffers = (data) => {
    console.log(data.data);
    return (
        <div className="bg-white rounded-lg p-7">
            <div className="flex items-center justify-between mb-6">
                <h2 className="text-[#11142D] text-base font-semibold">Offers</h2>
                <Link to="/offers/add">
                    <Button type="blueSolid">+ Upload Offer</Button>
                </Link>
            </div>
            <Table columnData={COLUMNS} rowData={data.data.length === 0 ? [] : data.data.offer_list} />
        </div>
    );
};

export default CardOffers;
