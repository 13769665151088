import React, { useMemo } from 'react';
import { useTable, usePagination } from 'react-table';

const Table = ({ columnData, rowData }) => {
    const columns = useMemo(() => columnData, [columnData]);
    const data = useMemo(() => rowData.length === 0 ? [] : rowData, [rowData]);

    const {
        getTableBodyProps,
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state
    } = useTable(
        {
            columns,
            data
        },
        usePagination
    );

    const { pageIndex } = state;

    const Pagination = () => {
        return (
            <div>
                <span>
                    Page {pageIndex + 1} of {pageOptions.length}
                </span>
                &nbsp;
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                </button>
                &nbsp;
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                </button>
            </div>
        );
    };

    return (
        <>
            <table {...getTableProps()} className="w-full">
                <thead className="text-left text-sm font-normal text-[#B2B3BD] ">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {/* <hr className="mt-7 border-0" /> */}
                <tbody {...getTableBodyProps()} className="border-t">
                    {
                        page.length === 0 ?
                            <tr ><th colSpan={headerGroups[0].headers.length} className="py-4 border-b"> {"---- No Record Found ----"}</th></tr>
                            :
                            page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td className="py-4 border-b" {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })
                    }
                </tbody>
            </table>
            <div>
                <Pagination />
            </div>
        </>
    );
};

export default Table;
