import React, { useState } from 'react';
import { BankIcon, EditIcon, TrashIcon, VerticalDotsIcon } from '../../assets';

import { Link, useNavigate } from 'react-router-dom';
import { bank_delete } from '../../action/bankAction';
import { useDispatch } from "react-redux";

const ActionCard = (data) => {
    const [actions, setActions] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ACTIONS = [
        {
            icon: BankIcon,
            title: 'View Card',
            action: () => navigate("/banks/" + data.data._id, { state: { "bank_logo": data.data.bank_logo, "bank_name": data.data.bank_name } })
        },
        {
            icon: BankIcon,
            title: 'Bank Offers',
            action: () => navigate("/bankOffers/" + data.data._id, { state: { "bank_logo": data.data.bank_logo, "bank_name": data.data.bank_name } })
        },
        {
            icon: EditIcon,
            title: 'Edit',
            action: () => navigate('/banks/editbank/' + data.data._id)
        },
        {
            icon: TrashIcon,
            title: 'Delete',
            action: () => BankDelete(data.data._id)
        }
    ];

    const BankDelete = async (id) => {
        console.log("in", id);
        var dtl = {
            'Id': id
        }
        var cat = await dispatch(bank_delete(dtl));
        if (cat.success) {
            window.location.reload();
        }
    }


    return (
        <div className="pl-3 relative">
            <button className="py-8"
                onMouseEnter={() => setActions(true)}
                onMouseLeave={() => setActions(false)}>
                <VerticalDotsIcon />
                {actions && (
                    <div className="items-start top-16 -left-16 flex py-2 w-36 flex-col absolute bg-white z-40 shadow-xl border rounded-lg border-kb-light-gray text-kb-dark-gray">
                        {ACTIONS.map((item) => (

                            <button
                                key={item.title}
                                className="px-4 py-2 w-full rounded-md flex gap-3 items-center hover:bg-kb-light-blue hover:text-kb-light-blue hover:bg-opacity-20"
                                onClick={item.action}
                            >
                                <item.icon />
                                <span>{item.title}</span>
                            </button>

                        ))}
                    </div>
                )}
            </button>
        </div>
    );
};

export default ActionCard;
