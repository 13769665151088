import React, { useState } from 'react';
import Table from '../utils/Table';
import OutletTableActions from './OutletTableActions';

const OutletsTable = ({ data }) => {
    const [activeRow, setActiveRow] = useState(0);

    const updateActiveRow = (id) => {
        setActiveRow(activeRow !== id ? id : -1);
    };

    const COLUMNS = [
        {
            Header: 'Brand/Outlet',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <span>{tableProps.row.original.outletname}</span>
                </div>
            )
        },

        {
            Header: 'Category',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <span>{tableProps.row.original.category.name}</span>
                </div>
            )
        },
        {
            Header: 'Status',
            Cell: (tableProps) => (tableProps.row.original.is_active.toString())
        },
        {
            Header: 'Actions',
            Cell: (tableProps) => (
                tableProps.row.original.is_active ? 
                <OutletTableActions data={tableProps.row.original._id} />
                :''
            )
        }
    ];

    return <Table rowData={data} columnData={COLUMNS} />;
};

export default OutletsTable;
