import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BlueSphere, BigDownloadCloud, ChervonDown } from '../assets';
import ApplicationColumn from '../components/Application/ApplicationColumn';
import Table from '../components/utils/Table';
import DATA from '../sitedata/applications.json';
import { app_list } from '../action/applicationAction';
import { useDispatch } from "react-redux";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Button from '../components/utils/Button';
import ApplicationActionCard from '../components/Application/ApplicationActionCard';

const Application = () => {
    const location = useLocation();
    // const const_url = window.location.href.split('/');
    // const Id = const_url[const_url.length - 1]
    // console.log("id",Id);
    const [application_list, setApplication] = useState([]);
    const [total_count, setCount] = useState(0);
    const [Status, setStatus] = useState(-1);
    const [app_for, setApplicationFor] = useState(' ');

    const dispatch = useDispatch();

    useEffect(() => {
        console.log("call3232332", Status);
        var payload = {
            'Status': Status,
            'ApplicationFor': app_for
        }
        console.log("call3232332", Status);
        var bank = dispatch(app_list(payload));
        bank.then(function (val) {
            console.log(val);
            setApplication(val.data);
            setCount(val.total_data);
        })
    }, [Status, app_for]);

    const COLUMNS = [
        {
            Header: 'Applicatio For',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        src={tableProps.row.original.card_id[0].card_image}
                        className="w-16 rounded-lg mr-4"
                        alt={tableProps.row.original.card_id[0].card_name}
                    />
                    <Link to={`${location.pathname}/CARDID`}>
                        <span className="font-bold">{tableProps.row.original.card_id[0].card_name}</span>
                    </Link>
                </div>
            )
        },
        {
            Header: 'Email ID',
            Cell: (tableProps) => (<span className="font-bold">{tableProps.row.original.user_id[0].email}</span>)
        },
        {
            Header: 'Phone Number',
            Cell: (tableProps) => (<span className="font-bold">{tableProps.row.original.user_id[0].country_code + ' ' + tableProps.row.original.user_id[0].phone}</span>)
        },
        {
            Header: 'Yearly Salary',
            Cell: (tableProps) => (<span>{tableProps.row.original.salary.length > 9 ? tableProps.row.original.salary.substr(0, 9) + '...' : tableProps.row.original.salary.length}</span>)
        },
        {
            Header: 'Emirates ID',
            accessor: 'emirates_id'
        },
        {
            Header: 'Company Working For',
            accessor: 'employeement_history'
        },
        {
            Header: 'Status',
            Cell: (tableProps) => tableProps.row.original.is_active.toString()
        },
        {
            Header: 'Actions',
            Cell: (tableProps) => <ApplicationActionCard data={tableProps.row.original._id} />
        }
    ];

    // if (application_list.length === 0) {

    //     ApplicationTabledata().then(data => {
    //         console.log(data.data);
    //         setApplication(data.data);
    //         setCount(data.total_data)
    //     });
    //     console.log("application_list", application_list);
    // }

    return (
        <>
            <div className="w-full pt-11 pb-8 px-10 text-3xl border-b">
                <div className="flex items-center">
                    <img src={BlueSphere} alt="none" className="w-14 h-14 mr-4" />
                    <span className=" font-bold">{total_count}&nbsp; </span>
                    <span className="font-light">Total Applications</span>
                </div>
            </div>
            <div className="py-5 px-10">
                <div className="w-full flex justify-between">
                    <div className="flex justify-start items-center gap-4 text-sm font-semibold text-kb-dark-gray mb-10">
                        <div className="">
                            <span>
                                Bank Name
                            </span>
                            <div className={`flex flex-col text-sm col-span-2 lg:col-span-1`}>
                                <input
                                    className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
                                    placeholder='Filter By Bank Name'
                                    type="text"
                                    onChange={(e) => setApplicationFor(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="grid">
                            <span>
                                Status
                            </span>
                            <select
                                className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option className="py-2" value={-1} > All </option>
                                <option className="py-2" value={1} >True</option>
                                <option className="py-2" value={0} >False</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex">

                        <Button onClick={() => ExpoetExcel(application_list, 'ApplicationData')}>
                            <img src={BigDownloadCloud} className="h-11" alt="download" />
                        </Button>

                    </div>
                </div>
                <div>
                    <Table columnData={COLUMNS} rowData={application_list} />
                </div>
            </div>
        </>
    );
};

// const ApplicationTabledata = async () => {

//     const dispatch = useDispatch();

//     var app = await dispatch(app_list());
//     return app;
// }

const ExpoetExcel = (excelData, fileName) => {
    var Export_data = [];
    for (let i = 0; i < excelData.length; i++) {
        Export_data.push({
            'Card Name': excelData[i].card_id[0].card_name,
            'Email': excelData[i].user_id.length > 0 ? excelData[i].user_id[0].email : "",
            'Phone': excelData[i].user_id.length > 0 ? excelData[i].user_id[0].country_code + ' ' + excelData[i].user_id[0].phone : "",
            'Yearly Salary': excelData[i].salary,
            'EmiratesId': excelData[i].emirates_id,
            'Company': excelData[i].employeement_history,
            'Status': excelData[i].is_active
        });
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(Export_data);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)
}

export default Application;
