import React,{useState} from 'react';
import { InputFile, InputText ,InputSelect} from '../utils/Input';
import BodyTextEditor from '../utils/TextEditor';

const CardForm = ({ values, onChange }) => {
   

    var option = [{'name':'Yes','_id':"Yes"},{'name':'No','_id':"No"}]

    return (
        <form className="grid grid-cols-2 gap-4">
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="card_name"
                    label="Card Name"
                    value={values === undefined ? '' : values.card_name }
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputFile
                    name="card_image"
                    label="Card Image"
                    val={values === undefined ? '' : values.card_image.name}
                    val1={values === undefined ? '' : values.card_image}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
               
                 <InputSelect
                    label="Card Type"
                    name="card_type"
                    value={values === undefined ? '' : values.card_type}
                    options={values === undefined ? '' : values.cardTypeList}
                    onChange={onChange}
                    placeholder="Card Type"
                    type="text"
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
               
               <InputSelect
                  label="Category"
                  name="category"
                  value={values === undefined ? '' : values.category}
                  options={values === undefined ? '' : values.categorylist}
                  onChange={onChange}
                  placeholder="Category"
                  type="text"
              />
          </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="annual_charges"
                    label="Annual Percentage Rate"
                    value={values === undefined ? '' : values.annual_charges}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputSelect
                    label="Balance Transfer"
                    name="balance_transfer"
                    value={values === undefined ? '' : values.balance_transfer}
                    options={option}
                    onChange={onChange}
                    placeholder="Balance Transfer"
                    type="text"
                    all={false}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                
                <InputSelect
                    label="CashBack"
                    name="cashback"
                    value={values === undefined ? '' : values.cashback}
                    options={option}
                    onChange={onChange}
                    placeholder="CashBack"
                    type="text"
                    all={false}
                />
                
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="minimum_salary"
                    label="Minimum Salary"
                    value={values === undefined ? '' : values.minimum_salary}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="fx_rate"
                    label="FX Rate"
                    value={values === undefined ? '' : values.fx_rate}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2 lg:col-span-1">
                <InputText
                    name="monthly_fees"
                    label="Monthly Fees"
                    value={values === undefined ? '' : values.monthly_fees}
                    onChange={onChange}
                />
            </div>
            <div className="col-span-2">
                <p className="text-[#8C969F] text-sm mb-4">Description</p>
                <BodyTextEditor name="other_description" 
                value={values.other_description}
                data={values.other_description}
                onChange={(item) => values.other_description = item}
                    />
            </div>
        </form>
    );
};

export default CardForm;
