import React from 'react';

function CategoryIcon({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.509"
            height="21.607"
            viewBox="0 0 19.509 21.607"
        >
            <g id="box" transform="translate(-2.245 -1.223)">
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M21,16V8a2,2,0,0,0-1-1.73l-7-4a2,2,0,0,0-2,0l-7,4A2,2,0,0,0,3,8v8a2,2,0,0,0,1,1.73l7,4a2,2,0,0,0,2,0l7-4A2,2,0,0,0,21,16Z"
                    fill="rgba(0,0,0,0)"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    opacity="1"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M3.27,6.96,12,12.01l8.73-5.05"
                    fill="rgba(0,0,0,0)"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    opacity="1"
                />
                <line
                    id="Line_38"
                    data-name="Line 38"
                    y1="10.08"
                    transform="translate(12 12)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    opacity="1"
                />
            </g>
        </svg>
    );
}

export default CategoryIcon;
