import React, { useState,useEffect } from 'react';
import RichTextEditor from 'react-rte';
import './texteditor.css';

const BodyTextEditor = (props) => {
    const [value, setValue] = useState(RichTextEditor.createValueFromString(props.data, 'html'));
    //const [value, setValue] = useState(RichTextEditor.createValueFromString(props.data, 'markdown'));
     //console.log(RichTextEditor.createEmptyValue());
     useEffect(() => {
        setValue(RichTextEditor.createValueFromString(props.data, 'html'));
    }, [props.data])
    
    const onChange = (value) => {
        setValue(value);
        //if (props.onChange) {
            props.onChange(value.toString('html'));
            return value.toString('html')
        //}
    };


    return (
        <div>
            <p className="text-sm text-[#8C969F] mb-4">{props.label}</p>
            {/* <RichTextEditor 
                className="h-96 z-0 richtexteditor cur table col-xl-12"
                value={value}
                onChange={onChange}
                editorClassName
            /> */}
             <RichTextEditor 
                className="richtexteditor"
                value={value}
                onChange={onChange}
                editorClassName
            />
        </div>
    );
};

export default BodyTextEditor;
