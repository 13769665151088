import React, { useState, useEffect } from 'react';
import AudienceReach from '../components/Overview/AudienceReach';
import SignUpRatio from '../components/Overview/SignUpRatio';
import Stats from '../components/Overview/Stats';
import Welcome from '../components/Overview/Welcome';
import { useDispatch } from "react-redux";
import { get_overview_dtl } from '../action/overviewAction';
import MetaData from '../component/layout/MetaData';

const Overview = () => {
    const dispatch = useDispatch();
    const [respo, setRespo] = useState();
    // var dtl = dispatch(get_overview_dtl()).then((dt)=>{
    //     console.log(dt);

    // })

    var dtl;

    const dtlbyid = async () => {

        var dtl = await dispatch(get_overview_dtl())

        setRespo(dtl);
    }
    useEffect(() => {
        console.log(dtl);
        if (dtl === undefined) {

            dtlbyid();
        }
    }, [dtl === undefined]);

    console.log(respo);

    return (
        <>
            <MetaData title="Overview" />
            <div className="flex flex-col px-16 py-12 gap-4">
                <Welcome data={respo} />
                <Stats data1={respo !== undefined ? respo.data : ''} />
                <div className="flex gap-4">
                    <SignUpRatio data={respo !== undefined ? respo.data : ''} />
                    <AudienceReach />
                </div>
            </div>
        </>
    );
};

export default Overview;
