import React from 'react';
import { GetOutletDtlByIdForView } from '../../action/outletAction';
import OutletOffersTable from '../../components/Outlets/OutletOffersTable';
import Button from '../../components/utils/Button';
import OutletLayout from '../../layouts/OutletLayout';

const Outlet = () => {
    const Id = window.location.href.split('/')[4];
    console.log(Id);
    // const [formData, setFormData] = useState({
    //     bank_logo:'',
    //     bank_name:'',
    //     bank_ifsc_code:'',
    //     bank_full_address:'',
    //     bank_country:'',
    //     state:'',
    //     city:'',
    //     other_description:'',
    //     citylist:[],
    //     id:Id
    // });
    // const dtlbyid = async () => {
    //     const data={
    //         Id:Id
    //     }
    //     var dtl = await dispatch(GetOutletDtlByIdForView(data));
    //     console.log(dtl);
    // if (dtl.success) {
    //     setFormData((prevState) => {
    //         prevState.id = Id;
    //         prevState.bank_logo = {'name':dtl.data[0].bank_logo};
    //         prevState.bank_name = dtl.data[0].bank_name;
    //         prevState.bank_ifsc_code = dtl.data[0].bank_ifsc_code;
    //         prevState.bank_full_address = dtl.data[0].bank_full_address;
    //         prevState.bank_country = dtl.data[0].bank_country;
    //         prevState.state = dtl.data[0].state[0];
    //         prevState.city = dtl.data[0].city[0];
    //         prevState.other_description = dtl.data[0].other_description;
    //         return { ...prevState };
    //     });
    // }
    // }
    return (
        <OutletLayout
            actions={
                <div className="flex gap-3 text-sm">
                    <Button type="magentaSolid">Edit </Button>
                    <Button type="redBorder">Delete</Button>
                </div>
            }
        >
            <div className="px-10 py-5 bg-kb-light-gray bg-opacity-30 shadow-inner-xl">
                <div className="bg-white py-8 px-6 rounded-lg">
                    <h2 className=" font-semibold text-base ">Outlet Details</h2>
                    <hr className="my-4" />
                    <div className="flex flex-col">
                        {[
                            { title: 'Bank Name', value: '27.77%' },
                            { title: 'Available on', value: 'Yes' },
                            { title: 'Offerdesc', value: 'Yes' },
                            { title: 'Offertag', value: 'SAR 30,000' },
                            { title: 'Offer Starts', value: '27.77%' },
                            { title: 'Offer Ends', value: 'Yes' },
                            { title: 'Country', value: 'Yes' },
                            { title: 'Location', value: 'SAR 30,000' },
                            { title: 'Outlet Latitude', value: 'SAR 30,000' },
                            { title: 'Outlet Longitude', value: 'SAR 30,000' },
                            {
                                title: 'Working Hours',
                                value: 'Monday to Friday - 10am - 11:30pm, Saturday - Sunday 10:am - 2am'
                            }
                        ].map((item) => (
                            <div className="flex pb-4 text-sm">
                                <span className="w-48 mr-2 text-[#B2B3BD] ">{item.title}</span>
                                <span className="mr-12">:</span>
                                <span className="text-[#11142D]">{item.value}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </OutletLayout>
    );
};

export default Outlet;
