import React from 'react';

function VerticalDotsIcon({ color = '#6e6b7b' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18">
            <g id="Group_23178" data-name="Group 23178" transform="translate(-30 -11)">
                <circle
                    id="Ellipse_55"
                    data-name="Ellipse 55"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(33 19) rotate(90)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
                <circle
                    id="Ellipse_56"
                    data-name="Ellipse 56"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(33 26) rotate(90)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
                <circle
                    id="Ellipse_57"
                    data-name="Ellipse 57"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(33 12) rotate(90)"
                    fill="none"
                    stroke={color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
            </g>
        </svg>
    );
}

export default VerticalDotsIcon;
