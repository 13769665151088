import React from 'react';
import Autocomplete from 'react-autocomplete'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const InputText = (props) => {
    return (
        <div className={`flex flex-col text-sm col-span-2 lg:col-span-1`}>
            <label className="font-normal text-[#8C969F] mb-4">{props.label}</label>
            <input
                value={'none'}
                className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
                {...props}
            />
        </div>
    );
};


const FilterStatusList = [
    {
        title: 'All',
        action: () => console.log('View Card Clicked')
    },
    {
        title: 'True',
        action: () => console.log('View Card Clicked')
    },
    {
        title: 'Fale',
        action: () => console.log('View Card Clicked')
    }
];

export const FilterSearch = (props) => {
    return (
        <div className="grid">
                    <span>
                        {props.title}
                    </span>
                    
                    {FilterStatusList.map((item) => (
                            <button
                                key={item.title}
                                className="px-4 py-2 w-full rounded-md flex gap-3 items-center hover:bg-kb-light-blue hover:text-kb-light-blue hover:bg-opacity-20"
                                onClick={item.action}
                            >
                                <span>{item.title}</span>
                            </button>
                        ))}
                    {/* <select
                className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
                onChange={(e) => setStatus(e.target.value)}
            >
                <option className="py-2" value={-1} > All </option>
                    <option className="py-2" value={1} >True</option>
                    <option className="py-2" value={0} >False</option>
            </select> */}
                </div>
    );
};

export const AutocompleteText = (value,onChange) => {
    return (
        <Autocomplete
            getItemValue={(item) => item.label}
            items={[
                { label: 'apple' },
                { label: 'banana' },
                { label: 'pear' }
            ]}
            renderItem={(item, isHighlighted) =>
                <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                    {item.label}
                </div>
            }
            value={value}
            onChange={onChange}
            onSelect={(val) => value = val}
        />

    );
};

export const InputSelect = ({ options = [], name, label, onChange, value,all }) => {
    return (
        <div className={`flex flex-col text-sm col-span-2 lg:col-span-1`}>
            <label htmlFor={name} className=" font-normal text-[#8C969F] mb-4">
                {label}
            </label>
            <select
                value={value}
                onChange={onChange}
                id={name}
                name={name}
                className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
            >
                <option className="py-2" value={-1} >
                    -- Select From List --
                    </option>

                {all === true && all !== undefined? <option className="py-2" value={1} >
                    ALL
                    </option> : ''}
                    

                {options.map((option) => (
                    <option className="py-2" value={option._id} key={option._id}>
                        {  option.name ? option.name : option.bank_name ? option.bank_name : option.outletname ? option.outletname : option.card_name ? option.card_name : ''}
                    </option>
                ))}
            </select>
        </div>
    );
};

export const InputFile = (props) => {
    const hiddenFileInput = React.useRef(null);

    const handleClick = (event) => {
        event.preventDefault();
        hiddenFileInput.current.click();
    };
    return (
        <div className={`flex flex-row text-sm col-span-2 lg:col-span-1`}>
            <label className=" font-normal text-[#8C969F] mt-11 mr-5">{props.label} :</label>
            <button
                onClick={handleClick}
                className="outline-none  border-[#E1E3E9] text-kb-dark-gray  rounded flex justify-between"
            >
                <div className="flex items-center">
                    {
                     <img
                         src={props.val1.size ? props.val1  ? URL.createObjectURL(props.val1) : null : props.val1.name}
                         className="w-20 h-20 rounded-lg mr-4"
                         alt={props.val1}
                     />
                     }
                 </div>
                {/* <span className="#text-[#CCCCCC] p-2">{props.val ? props.val :'Select a file'}</span> */}
                <span className="text-kb-light-blue px-5 py-2 bg-opacity-30 bg-kb-light-gray mt-9 ml-5">
                    Upload
                </span>
            </button>
            <input {...props} ref={hiddenFileInput} type="file" className="hidden" />
        </div>
    );
};


export const InputDatePicker = (props) => {
    return (
        <div className={`flex flex-col text-sm col-span-2 lg:col-span-1`}>
            <label className="font-normal text-[#8C969F] mb-4">{props.label}</label>
            <DatePicker
             className="outline-none border-2 border-[#E1E3E9] placeholder-[#CCCCCC] text-kb-dark-gray p-2 rounded"
             selected={props.value}  
             onChange={props.onChange}
             dateFormat={'dd/MM/yyyy'} 
             minDate={props.min}/>
        </div>
    );
};


// export default Input;
