import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Table from '../utils/Table';
import UsersTableActions from './UsersTableActions';
import ActionCard from '../utils/ActionCard';
import { ApplicationIcon, TrashIcon } from '../../assets';
import { user_block, user_del } from '../../action/userAction';
import { useDispatch } from 'react-redux';


const UsersTable = ({ data }) => {


    const COLUMNS = [
        {
            Header: 'User Name',
            Cell: (tableProps) => (
                <div className="flex items-center">
                    <img
                        src={tableProps.row.original.photo}
                        className="w-11 rounded-full mr-4"
                        alt=' '
                    />
                    <Link to={`/users/${tableProps.row.original._id}`}>
                        <span>{tableProps.row.original.name}</span>
                    </Link>
                </div>
            )
        },

        {
            Header: 'Phone no.',
            Cell: (tableProps) => <span className="">{tableProps.row.original.country_code + " " + tableProps.row.original.phone}</span>
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Gender',
            accessor: 'gender'
        },
        {
            Header: 'Cards',
            Cell: (tableProps) => <span className="">{tableProps.row.original.total_card.length} Cards</span>
        },
        {
            Header: 'Applications',
            Cell: (tableProps) => (
                <span className="text-kb-light-blue">
                    {tableProps.row.original.total_application.length} Applications
                </span>
            )
        },

        {
            Header: 'Actions',
            Cell: (tableProps) => (
                tableProps.row.original.is_active &&
                <UsersTableActions
                    data={tableProps.row.original._id}
                />
            )
        }
    ];

    return <Table columnData={COLUMNS} rowData={data} />;
};

export default UsersTable;
