import React, { useState } from 'react';
import { BlueSphere,OrangeSphere,Spiral } from '../../assets';



const StatItem = ({ img_src,title, value, description }) => {
    const [active, setActive] = useState(false);
    return (
        <>
            <div
                className="col-span-1 flex relative"
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
            >
                <div className="mr-5">
                    <img src={img_src} alt="none" className="h-14 w-14" />
                </div>
                <div className="py-2 w-36">
                    <h3 className="text-[#11142D] opacity-60 text-[14px] leading-4">{title}</h3>
                    <h3 className="text-[#11142D] font-bold text-[18px] leading-5">{value}</h3>
                </div>
                <span className="py-3"><b>{'>'}</b></span>
                {active && (
                    <div className="bg-white shadow-lg absolute top-full mt-2 px-6 py-4 z-50 w-64 rounded-3xl">
                        {description}
                    </div>
                )}
            </div>
        </>
    );
};

const Stats = (data1) => {
    console.log(data1);
    const data = [
        {
            title: 'Total Users',
            value: data1.data1.user_count,
            description: `This will showcase how many users are sign up on our platform.`,
            img_src:BlueSphere
        },
        {
            title: 'Total Cards',
            value: data1.data1.bankcard_count,
            description: (
                <>
                    <p className="mb-4">No of cards & Data Like This titles.</p>
                    <ol className="list-decimal px-4 text-xs">
                        <li>Terms & Condition of card</li>
                        <li>Type of cards</li>
                        <li>Annual Charges</li>
                        <li> Other Titles & Subtitles</li>
                    </ol>
                </>
            ),
            img_src:OrangeSphere
        },
        {
            title: 'Total Categories',
            value: data1.data1.category_count,
            description: (
                <>
                    <p>Two Types of category</p>
                    <ol className="list-decimal px-4 text-xs">
                     <li> To know the interest of users in first phase </li>
                     <li> User Expense category </li>
                     </ol>
                </>
            ),
            img_src:Spiral
        },
        {
            title: 'Card Application',
            value: data1.data1.application_count,
            description: "Total no of User's payment card application will show here.",
            img_src:BlueSphere
        },
        {
            title: 'No Of Outlet',
            value: data1.data1.outlet_count,
            description: 'Total no of outlets & shops that are registered on our platform',
            img_src:Spiral
        },
        {
            title: 'Trending Offers',
            value: data1.data1.offer_count,
            description: 'Application this section banners will go here',
            img_src:BlueSphere
        }
    ];

    return (
        <div className="relative z-50 flex justify-center">
            <div className="h-full top-16 bg-[#E3E6EC] mix-blend-multiply rounded-3xl blur-2xl w-full opacity-90 -z-10 absolute" />
            <div className="bg-white rounded-3xl w-full h-full py-9 px-8 grid grid-cols-2 gap-10">
                {data.map((item, index) => (
                    <StatItem
                        key={index}
                        title={item.title}
                        value={item.value}
                        description={item.description}
                        img_src={item.img_src}
                    />
                ))}
            </div>
        </div>
    );
};

export default Stats;
