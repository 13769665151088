import React from 'react';
import { Outlet } from 'react-router-dom';
import BankHeader from '../components/Banks/BankHeader';

const BankLayout = ({ children }) => {
    return (
        <>
            <BankHeader />
            <hr className="" />
            <Outlet />
        </>
    );
};

export default BankLayout;
