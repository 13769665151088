import React from 'react';

const AudienceReach = () => {
    return (
        <div className="bg-[#f2f4f6] py-4 w-full rounded-3xl">
            <h2 className="text-[#11142D] px-8 pb-5 text-[18px] font-medium">Audience Reach</h2>
            <div className="bg-white mx-2  rounded-3xl flex flex-col justify-between">
                <h2 className="p-3 ">Content</h2>
                <div className="m-4">
                    <button className="w-full py-5 px-16 bg-kbc-pink text-white rounded-2xl text-[14px] font-bold text-center">
                        Go Analytics
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AudienceReach;
