import React, { useState, } from 'react';
import { user_block, user_del } from '../../action/userAction';
import { CardIcon, TrashIcon, VerticalDotsIcon, OutletIcon, ApplicationIcon } from '../../assets';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const UsersTableActions = (data) => {
    const [activeRow, setActiveRow] = useState(data.data);
    const [actions, setActions] = useState(false);
    const dispatch = useDispatch();

    const ACTIONS = [
        {
            icon: ApplicationIcon,
            title: 'View Detail',
            action: () => console.log('View Card Clicked of', activeRow)
            //navigate(`/users/${activeRow}`)
        },
        {
            icon: ApplicationIcon,
            title: 'Block User',
            action: () => UserBlock(data.data)
        },
        {
            icon: TrashIcon,
            title: 'Delete',
            action: () => UserDelete(data.data)
        }
    ];

    const UserDelete = async (id) => {
        var dtl = {
            'Id': id
        }
        var cat = await dispatch(user_del(dtl));
        if (cat.success) {
            window.location.reload();
        }
    }

    const UserBlock = async (id) => {
        var dtl = {
            'Id': id
        }
        var cat = await dispatch(user_block(dtl));
        if (cat.success) {
            window.location.reload();
        }
    }
    return (
        <div className="pl-3 ">
            <div className="relative w-8 h-8">
                <button className="hover:bg-gray-300 w-8 h-8 flex items-center justify-center rounded-full py-3"
                    onMouseEnter={() => setActions(true)}
                    onMouseLeave={() => setActions(false)}
                >
                    <VerticalDotsIcon />

                    {actions && (
                        <div className="items-start top-8 -left-16 flex py-2 w-36 flex-col absolute bg-white z-40 shadow-xl border rounded-lg border-kb-light-gray text-kb-dark-gray">
                            {ACTIONS.map((item) => (
                                <button
                                    key={item.title}
                                    className="px-4 py-2 w-full rounded-md flex gap-3 items-center hover:bg-kb-light-blue hover:text-kb-light-blue hover:bg-opacity-20"
                                    onClick={item.action}
                                >
                                    <item.icon />
                                    <span className="text-xs">{item.title}</span>
                                </button>
                            ))}
                        </div>
                    )}
                </button>
            </div>
        </div>
    );
};

export default UsersTableActions;
